import React, { useRef } from "react";
import "../../Assets/css/contactus.css";
import { Row, Col, Container, Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import TimezoneSelect from "react-timezone-select";
import TimePicker from "react-bootstrap-time-picker";
import "date-fns";
import Helmet from "react-helmet";
import consultationImg from "../../Assets/images/home/consulation.png";
import "../../Assets/css/consultation.css";
import axios from "axios";
import { baseURL, postHeader } from "../../Helpers/request";
import swal from "sweetalert";
import "../../Assets/css/Sweetalert.css";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "react-js-loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useState } from "react";
import { useHistory } from "react-router-dom";

const Consultation = () => {
  const history = useHistory();
  const captchaRef = useRef(null);
  const [selectedDate, setSelectedDate] = React.useState("");
  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const [business, setBusiness] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [time, setTime] = React.useState("10:00");
  const [timefeild, setTimefeild] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [timezone, setTimezone] = React.useState("");
  const [load, setLoad] = React.useState(false);
  const [captcha, setCaptcha] = React.useState(true);
  const [services, setServices] = useState([]);

  function onChange(value) {
    if (value) {
      setCaptcha(false);
    }
  }
  const secondsToHms = (e) => {
    e = Number(e);
    var hr = Math.floor(e / 3600);
    var mr = Math.floor((e % 3600) / 60);

    var hDisplay = hr > 0 ? hr : "00";
    var mDisplay = mr > 0 ? mr : "00";
    return `${hDisplay} : ${mDisplay}`;
  };
  const SubmitAppointment = (e) => {
    e.preventDefault();
    captchaRef.current.reset();
    const day = new Date(selectedDate).getUTCDay();
    if (phone.length > 7) {
      if (day == 6 || day == 0) {
        swal("please choose monday to friday", { icon: "warning" });
      } else {
        const data = {
          first_name: firstname,
          last_name: lastname,
          email: email,
          phone: phone.length ? `+${phone}` : "",
          website_url: website,
          business_name: business,
          // date: `${new Date(selectedDate).getDate()}-${new Date(
          //   selectedDate
          // ).getMonth() + 1}-${new Date(selectedDate).getFullYear()}`,
          // time: time,
          // message: message,
          // timezone: timezone.label,
          services: services
        };
        setLoad(true);
        axios
          .post(`${baseURL}/appointment/book_an_appointment`, data, {
            headers: postHeader,
          })
          .then((res) => {
            if (res.data.status == 1) {
              // swal(res.data.message, { icon: "success" });
              setSelectedDate("");
              setFirstname("");
              setLastname("");
              setPhone("");
              setEmail("");
              setMessage("");
              setTime("10:00");
              setTimefeild("");
              setTimezone("");
              setBusiness("");
              setWebsite("");
              setLoad(false);
              history.push('./thankyou')
            } else {
              swal(res.data.message, { icon: "warning" });
              setLoad(false);
            }
          })
          .catch((error) => {
            if (error) {
              swal("something went wrong", { icon: "error" });
              setLoad(false);
            }
          });
      }

    } else {
      setPhoneError('Please Enter Valid Number');
    }
  };
  const selectService = (val) => {
    if (services.includes(val)) {
      var arr = services.filter(value => value != val);
      setServices(arr);
    } else {
      var arr = services.push(val);
    }
  }

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Book A Consultation Call With Our Experts | Belgium WebNet
        </title>
        <meta
          name="description"
          content="Drive your online store towards success with experts by your side. Subscribe to our services – Book a free consultation with Belgium WebNet today!"
        ></meta>
        <meta name="keywords" content="book now, book free consultation"></meta>
      </Helmet>
      <section className="bw_contactus_section position-relative">
        <div className="bw_all_banner bw_contact_banner bw_consultation_banner">
          <Container>
            <Row>
              <Col
                sm={12}
                md={12}
                lg={12}
                className="bw_consultation_banner_text"
              >
                <div>
                  <h1 className="text-center text-white">
                    Book A Free Consultation Call
                  </h1>
                  <p className="text-white text-center">Let The Expert Guide You!</p>
                </div>
              </Col>
              {/* <Col sm={12} md={6} lg={6} className="bw_consultation_banner_img">
                <LazyLoadImage
                  src={consultationImg}
                  alt="Consultaion Image"
                  className="img-fluid"
                />
              </Col> */}
            </Row>
          </Container>
        </div>
        <div className="bw_new_position_box bw_consulation_from_position mb-5">
          <Container>
            <Row className="w-100 m-auto px-0">
              <Col className="px-0">
                <div
                  className="bw_contact_form_outer_box"
                >
                  <h2>Let's Book An Appointment</h2>
                  <div className="bw_contact_form_inner_box">
                    <Form
                      className="bw_contact_us_form"
                      onSubmit={SubmitAppointment}
                    >
                      <Row>
                        <Col sm={12} md={12} lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Company Name*</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Company Name"
                              value={business}
                              onChange={(e) => setBusiness(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="First Name"
                              value={firstname}
                              onChange={(e) => setFirstname(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Last Name"
                              value={lastname}
                              onChange={(e) => setLastname(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Email Id"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Phone Number</Form.Label>
                            <PhoneInput
                              disableSearchIcon={true}
                              enableSearch={true}
                              country={"us"}
                              value={phone}
                              onChange={setPhone}
                              onlyCountries={[
                                "us",
                                "cn",
                                "in",
                                "au",
                                "jp",
                                "hk",
                                "kw",
                                "gb",
                                "es",
                              ]}
                              inputProps={{
                                name: "phone",
                                required: true,
                                autoFocus: false,
                              }}
                              isValid={(value, country) => {
                                console.log('value', value.length);
                                if (value.length < 10) {
                                  return "please enter valid number";
                                } else {
                                  return true;
                                }
                              }}

                            />
                            {!phoneError.length ? '' : <p className="phone_field_error font-weight-light text-danger">{phoneError}</p>}
                          </Form.Group>
                        </Col>

                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Website</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Website"
                              value={website}
                              onChange={(e) => setWebsite(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        {/* <Col sm={12} md={4} lg={4}>
                          <Form.Label>Date</Form.Label>
                          <Form.Control
                            type="date"
                            timefo
                            placeholder="Pick your date"
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(e.target.value)}
                            min={disablePastDate()}
                            required
                            className="mb-3"
                          />
                        </Col>
                        <Col sm={12} md={4} lg={4}>
                          <Form.Label>Time</Form.Label>
                          <TimePicker
                            start="10:00"
                            end="21:00"
                            placeholder="Your Preferred Time For The Call*"
                            step={30}
                            value={timefeild}
                            onChange={(e) => {
                              setTime(secondsToHms(e));
                              setTimefeild(e);
                            }}
                            className="mb-3"
                            required
                          />
                        </Col>
                        <Col sm={12} md={4} lg={4}>
                          <Form.Label>Timezone</Form.Label>
                          <TimezoneSelect
                            value={timezone}
                            onChange={setTimezone}
                            className="mb-3 bw_time_zone"
                          />
                        </Col> */}
                        {/* <Col sm={12} md={12} lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Notes</Form.Label>
                            <Form.Control
                              as="textarea"
                              placeholder="Your Message*"
                              rows={4}
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col> */}
                        <Col sm={12} md={12} lg={12}>

                          <Form.Label>Services</Form.Label>
                          <FormGroup aria-label="position" row className="mb-3">
                            <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
                              <FormControlLabel
                                value="end"
                                label="Website Design & Development"
                                control={<Checkbox />}
                                labelPlacement="end"
                                className="bwn_consultation_form_checkbox"
                                onChange={() => selectService("Website Design & Development")}
                              />
                            </Col>


                            <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
                              <FormControlLabel
                                value="end"
                                control={<Checkbox />}
                                label="Shopify Development"
                                labelPlacement="end"
                                className="bwn_consultation_form_checkbox"
                                onChange={() => selectService("Shopify Development")}
                              />
                            </Col>

                            <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
                              <FormControlLabel
                                value="end"
                                control={<Checkbox />}
                                label="Social Media Marketing"
                                labelPlacement="end"
                                className="bwn_consultation_form_checkbox"
                                onChange={() => selectService("Social Media Marketing")}
                              />
                            </Col>

                            <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
                              <FormControlLabel
                                value="end"
                                control={<Checkbox />}
                                label="Search Engine Optimization"
                                labelPlacement="end"
                                className="bwn_consultation_form_checkbox"
                                onChange={() => selectService("Search Engine Optimization")}
                              />
                            </Col>

                            <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
                              <FormControlLabel
                                value="end"
                                control={<Checkbox />}
                                label="Email Marketing"
                                labelPlacement="end"
                                className="bwn_consultation_form_checkbox"
                                onChange={() => selectService("Email Marketing")}
                              />
                            </Col>

                            <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
                              <FormControlLabel
                                value="end"
                                control={<Checkbox />}
                                label="Paid Marketing"
                                labelPlacement="end"
                                className="bwn_consultation_form_checkbox"
                                onChange={() => selectService("Paid Marketing")}
                              />
                            </Col>
                          </FormGroup>

                        </Col>
                        <Col sm={12} md={12} lg={12}>
                          <div className="rcs_cap_main">
                            <ReCAPTCHA
                              className="rcs_g-recaptcha"
                              ref={captchaRef}
                              sitekey="6Lc6LUscAAAAAKO2DYPGrIPSnwx3fzROCxJzNWQ0"
                              onChange={onChange}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <button disabled={load || captcha ? true : false}>
                          {load ? (
                            <div className="bw_loader_style">
                              <Loader
                                type="spinner-default"
                                bgColor={"#FFFFFF"}
                                color={"#FFFFFF"}
                                size={20}
                              />
                            </div>
                          ) : (
                            "submit"
                          )}
                        </button>
                      </Row>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default React.memo(Consultation);
