import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Form, Image } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { baseURL, postHeader } from "../../../Helpers/request";
import swal from "sweetalert";
import "../../../Assets/css/Sweetalert.css";
import "../../../Assets/css/crGemsApi.css";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "react-js-loader";
import CrGemsLaptoImg from "../../../Assets/images/integrations/jewels-terri-img.webp";
import RightArrowImg from "../../../Assets/images/integrations/right-arrow-img.png";
import userImg from "../../../Assets/images/integrations/gnf/user_t.png";


import { useHistory } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { TramRounded } from "@material-ui/icons";
import { isMobileOnly } from "react-device-detect";
// import ReactPlayer from "react-player/lazy";
const CrGemsWithoutHeaderFooter = () => {
  const history = useHistory();
  const captchaRef = useRef(null);
  const [name, setname] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setmessage] = useState("");
  const [load, setLoad] = useState(false);
  const [captcha, setCaptcha] = React.useState(true);
  function onChange(value) {
    if (value) {
      setCaptcha(false);
    }
  }

  const SubmitAppointment = (e) => {
    e.preventDefault();
    captchaRef.current.reset();
    const data = {
      name: name,
      company: company,
      email: email,
      phone: phone.length ? `+${phone}` : "",
      message: message,
    };
    setLoad(true);
    axios
      .post(`${baseURL}/contact/send_crgems_enquiry`, data, {
        headers: postHeader,
      })
      .then((res) => {
        if (res.data.status == 1) {
          setname("");
          setCompany("");
          setEmail("");
          setPhone("");
          setmessage("");
          swal(res.data.message, { icon: "success" });
          // history.push("./thankyou");
        } else {
          swal(res.data.message, { icon: "warning" });
          setLoad(false);
        }
      })
      .catch((error) => {
        if (error) {
          swal("something went wrong", { icon: "error" });
          setLoad(false);
        }
      });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Belgium WebNet- Nivoda</title>
        <meta
          name="description"
          content="The best digital marketing service provider to grow your business online. Belgium WebNet is the best social media marketing company to conquer social media."
        ></meta>
        <meta name="keywords" content=""></meta>
      </Helmet>
      <section className="bw_services_section_main bw_cr_gems_section bw_cr_gems_api_integration_Section_new">
        <div className="bw_all_banner bw_banner_pricing">
          <Container>
            <Row className="w-100 m-auto">
              <Col sm={12} md={12} lg={12}>
                <h1 className="bw_banner_heading text-uppercase">
                API INTEGRATION WITH CR GEMS
                </h1>
                <p className="bw_banner_sub_heading">
                Brought to you by Belgium WebNet
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <div className="bw_gems_video_section">
        <Container className="bw_custome_container">
          <Row>
            <Col
              sm={12}
              md={12}
              lg={6}
              className="d-flex align-items-center cr_img_order1"
            >
              <div className="bw_cr_api_integration_text_box">
                <h2>SEAMLESSLY LINK YOUR WEBSITE TO THOUSANDS OF STONES</h2>
                <p>
                By integrating our
loose gemstone inventory within your
website, our products will appear in your
own website design and without our
branding, free for your customers to
browse and enquire about any of our
stock, as if it’s your own, and directly via
your website. 
                </p>
                <p>Complete with the added
benefits of our efficient <strong> search filters,
360 interactive macro videos</strong> and <strong> real-
time product availability updates,</strong>
where any changes are immediately
reflected by what’s on offer to your
customers. </p>
                <p>In addition, you can <strong>adjust
price mark-ups</strong> and therefore the price
shown to your customer, to align with
your own margins.</p>
<hr></hr>
<p>See this API feature in action on Jewels by Terri website, where all loose gemstone inventory is integrated <strong>CR Gems:<a href="https://www.jewelsbyterri.com/gemstones" target="_blank"  className="text-dark" > https://www.jewelsbyterri.com/gemstones</a></strong> </p>
      {/* <hr></hr>  */}
      <div className="cr_testimonial_box">
        <div className="cr_testimonial_box_img">
          <img src={userImg} alt="cr gems logo" className="img-fluid" /> 
          </div>
          <div>
          <p className="mb-0">Jas and his team have made it so handy for me to get involved selling beautiful gemstones.  I’m thrilled with how smooth it’s been.</p>
          <strong>- Terri Levitsky, Jewels by Terri</strong>
          </div>
          
        </div>
        </div>     
            </Col>
            <Col sm={12} md={12} lg={6} className="cr_img_order2">
              <div
                className="bw_ce_gems_api_integration_lap_top_img_box"
              >
                <Image
                  src={CrGemsLaptoImg}
                  alt="Gems laptop Image"
                  className="img-fluid"
                  onClick={()=> window.open("https://www.jewelsbyterri.com/gemstones", "_blank")}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <section className="apiSec bw_cr_gems_api_integration_Section_second">
        <Container className="bw_custome_container">
          <div className="apiSecUnder cr_gems_sectionn">
            <Row className="align-items-center">
              <Col lg={6} md={12}>
                <Row>
                  <Col lg={11}>
                    <div className="imgWrp">
                      <img
                        src={require("../../../Assets/images/integrations/cr-gems-img.png")}
                        className="img-fluid"
                        alt=""
                      />
                    </div>

                    <h3 className="colorBlue mb-3">
                    HOW IT WORKS
                      {/* <span className="belgium">Belgium</span> <span className="webnet">WebNet</span> */}
                    </h3>
                    <p className="fs15">
                    API integration is free of charge to any business with their own IT team or personnel who we’ll put in contact with Belgium WebNet to work together and implement our inventory.
                    </p>
                    <div className="ce_gems_how_it_work_section">
                    <strong className="mb-3 mt-3">There are 4 web API functions:</strong>
                <ul >
                  <li>GetFilters</li>
                  <li>GetStones</li>
                  <li>GetFiltersCSV</li>
                  <li>GetFiltersCSV</li>
                </ul>
                <strong>Fill out the form below to get started today!</strong>
                    </div>
                  

                    <div className="btnsec d-flex justify-content-between flex-wrap w-100 align-items-center">
                      <div className="bw_contact_us_form ">
                        <AnchorLink className="btnStyle" href="#start">
                          {" "}
                          Get Started
                        </AnchorLink>
                      </div>
                      {/* <div>
                                                <img className="img-fluid" src={require('../../../Assets/images/integrations/diamond_11.jpg')} alt="" />
                                            </div> */}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={6} md={12}>
                <div className="positon-relative">
                  <img
                    className="mob_niv"
                    src={require("../../../Assets/images/integrations/gems-mob.png")}
                    alt=""
                  />
                  {isMobileOnly ? (
                    <div class="wistia_responsive_padding">
                      <div class="wistia_responsive_wrapper">
                        <div class="wistia_embed wistia_async_f1f1dhh0j3 seo=false videoFoam=true">
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  ) : (
                    <video className="w-100" muted loop autoPlay={true}>
                      <source
                        src={require("../../../Assets/images/integrations/cr-gems.mp4")}
                      />
                    </video>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <section className="bw_sercices_image_section bgDarkBlue1 bw_cr_gems_api_integration_Section_second" id="start">
        <Container className="">
          <Form className="bw_contact_us_form" onSubmit={SubmitAppointment}>
            <div className="bw_contact_form_outer_box mb-4">
              <h2 className="w-100 mb-5">Let's start your Integration</h2>
              <div className="formWrapper1 mb-4">
                <div className="bw_contact_form_inner_box">
                  <Row>
                    <Col lg={6} md={6} sm={12}>
                      <div className="input-control mb-4">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setname(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </div>
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      <div className="input-control mb-4">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Company Name*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Company Name"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </div>
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      <div className="input-control mb-4">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Email Address</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Email Id"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </div>
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      <div className="input-control mb-4">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Phone Number</Form.Label>
                          <PhoneInput
                            disableSearchIcon={true}
                            enableSearch={true}
                            country={"us"}
                            value={phone}
                            onChange={setPhone}
                            onlyCountries={[
                              "us",
                              "cn",
                              "in",
                              "au",
                              "jp",
                              "hk",
                              "kw",
                              "gb",
                              "es",
                            ]}
                            inputProps={{
                              name: "phone",
                              required: true,
                              autoFocus: false,
                            }}
                            isValid={(value, country) => {
                              if (value.length < 10) {
                                return "please enter valid number";
                              } else {
                                return true;
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                    </Col>
                    <Col lg={12} md={12} sm={12}>
                      <div className="input-control mb-4">
                        <label>Type in your comment or query…</label>
                        <textarea
                          value={message}
                          onChange={(e) => setmessage(e.target.value)}
                          name=""
                          id=""
                          className="form-control radius10"
                        ></textarea>
                      </div>
                    </Col>
                    <Col sm={12} md={12} lg={12}>
                      <div className="rcs_cap_main">
                        <ReCAPTCHA
                          className="rcs_g-recaptcha"
                          ref={captchaRef}
                          sitekey="6Lc6LUscAAAAAKO2DYPGrIPSnwx3fzROCxJzNWQ0"
                          onChange={onChange}
                        />
                      </div>
                    </Col>
                  </Row>

                  <button disabled={load || captcha ? true : false}>
                    {load ? (
                      <div className="bw_loader_style">
                        <Loader
                          type="spinner-default"
                          bgColor={"#FFFFFF"}
                          color={"#FFFFFF"}
                          size={20}
                        />
                      </div>
                    ) : (
                      "submit"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </Container>
      </section>
    </>
  );
};

export default React.memo(CrGemsWithoutHeaderFooter);
