import React, { useEffect, useState } from "react";
// import "../../Assets/css/diamondJewelryWebDevelopment.css";
import Helmet from "react-helmet";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
// import "../../Assets/css/plansPricingSeo.css";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "react-bootstrap/Table";
import { FiCheck } from "react-icons/fi";
import ClearIcon from "@mui/icons-material/Clear";
import CountUp from "react-countup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { currencycode } from "../../../Helpers/request";
import BandBuilderVideo from "../../../Assets/band-builder-video1.mp4";

function TabPanel(props) {
  const { children, value, valueInner, value1, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  valueInner: PropTypes.number.isRequired,
};
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value1: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ShopifyPluginGBP = () => {
  const history = useHistory();
  const handleClick = () => history.push("/package-form");

  const [value, setValue] = React.useState(0);
  const [valueInner, setValueInner] = React.useState(0);
  const [value1, setValue1] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangenew = (event, newValueInner) => {
    setValueInner(newValueInner);
  };
  const handleChange1 = (event, newValueInner1) => {
    setValue1(newValueInner1);
  };
  const [currency, setCurrency] = useState("GBP");

  const [setupFee, setSetupFee] = useState(1099); // Default fee (Diamonds)

  const handleFeeChange = (event) => {
    setSetupFee(Number(event.target.value)); // Update setup fee based on selection
  };
  useEffect(() => {
    if (value1 === 0) {
      setSetupFee(1099); // Ring Builder Default Value
    } else if (value1 === 1) {
      setSetupFee(1499); // Band Builder Default Value
    }
  }, [value1]);

  useEffect(() => {
    if (value1 === 0) {
      setSetupFee(1099); // Ring Builder Default Value
    } else if (value1 === 1) {
      setSetupFee(1499); // Band Builder Default Value
    }
  }, [value]);
  useEffect(() => {
    if (value1 === 0) {
      setSetupFee(1099); // Ring Builder Default Value
    } else if (value1 === 1) {
      setSetupFee(1499); // Band Builder Default Value
    }
  }, [valueInner]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Plugin | Shopify Website Development | Belgium WebNet</title>
        <meta
          name="description"
          content="As a Shopify development company, our plans include diamond and engagement ring inventory integration, & more. Choose the best plan for your business with us!"
        ></meta>
        <meta
          name="keywords"
          content="Shopify website development company"
        ></meta>
      </Helmet>
      <section className="bwn_shopify_plugin_sectionnn">
        <div className="bw_pricing_section">
          <div className="bw_all_banner bw_banner_pricing">
            <Container className="bw_custome_container">
              <Row className="w-100 m-auto">
                <Col>
                  <h1 className="bw_banner_heading">
                    Shopify & WordPress Plugin 
                  </h1>
                  <p className="bw_banner_sub_heading">
                    Choose a plan to avail our services and get started with
                    your transformation journey today.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
          <Tabs
            value={value1}
            onChange={handleChange1}
            aria-label="basic tabs example"
            centered
            className="bwn_shopify_plugin_tab_pading_top"
          >
            <Tab label="Ring Builder, Diamond & Gemstone Search Plugin" {...a11yProps(0)} className="bw_ring_builder_tab" />
            <Tab label="Band Builder Plugin" {...a11yProps(1)} className="bw_ring_builder_tab bw_band_builder_tab" />
          </Tabs>
          <TabPanel
            value={value1}
            index={0}
            className="bw_monthly_tab_outer_box"
          >

            <div className="bw_pricing_tabs_section">
              <Container className="bw_custome_container_pricing position-relative">
                <div className="bw_pricing_tabs_box bw_only_month_tab text-center position-relative">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    centered
                  >
                    <Tab label="Monthly" {...a11yProps(0)} />
                    <Tab label="Annually" {...a11yProps(1)} />
                  </Tabs>
                </div>
                <div className="bwn_first_month_free_text text-center">
                  <div className="position-relative">
                    <button className="bwn_get_on_month_free_button">
                      Annually Get 1 Month Free
                    </button>
                    {/* <Image src={ArrowImpImg}></Image> */}
                  </div>
                </div>
                {/* Montly tab containe start */}
                {/* {window.location.pathname.includes("-gbp") ? <Row className="w-100 m-0">
               <Col xl={1} lg={2} md={12} xs={10} className="inr_input_main">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Currency</InputLabel>
                    <Select
                      size={"small"}
                      inputProps={{ MenuProps: { disableScrollLock: true } }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={currency}
                      label="Currency"
                      onChange={(e) => setCurrency(e.target.value)}
                    >
                      <MenuItem value={"INR"}>INR</MenuItem>
                      <MenuItem value={"USD"}>USD</MenuItem>
                      <MenuItem value={"GBP"}>GBP</MenuItem>
                    </Select>
                  </FormControl>

                </Col>

              </Row> : ""} */}
                <TabPanel
                  value={value}
                  index={0}
                  className="bw_monthly_tab_outer_box"
                >
                  <Container className="bw_custome_container_pricing bw_pricing_tab_desktopview">
                    <Row className="w-100 m-auto">
                      <Col className="px-0">
                        <Table className="bw_pricing_montly_table">
                          <thead>
                            <tr className="border-top-0">
                              <th className="text-uppercase bw_pricing_package_heading align-middle w-20">
                                <div className="bw_mont_inner_heading_box bw_hair_salone_headingbox">
                                  <h6>
                                    Package <br /> Features{" "}
                                  </h6>
                                </div>
                              </th>

                              <th className="bw_pricing_package_heading_lower text-center  w-20">
                                <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border text-center">
                                  <h6>Ring Builder Plugin</h6>
                                  <h4>
                                    <CountUp
                                      end={
                                        window.location.pathname.includes(
                                          "-gbp"
                                        ) && currency == "INR"
                                          ? 8000
                                          : currency == "GBP"
                                            ? 149
                                            : 200
                                      }
                                      duration={0}
                                      className="bwn_price_count_up"
                                      prefix={
                                        window.location.pathname.includes(
                                          "-gbp"
                                        ) && currency == "INR"
                                          ? "₹"
                                          : currency == "GBP"
                                            ? "£"
                                            : "$"
                                      }
                                      suffix="/"
                                    />
                                    <span>Month</span>
                                  </h4>
                                  <Button
                                    className="bw_btn_price_start"
                                    onClick={handleClick}
                                  >
                                    START
                                  </Button>
                                  <p>One-Time Setup Fees </p>
                                  {/* <h4>
                                    {window.location.pathname.includes("-gbp") &&
                                      currency == "INR"
                                      ? "₹100000"
                                      : currency == "GBP"
                                        ? "£1099"
                                        : "$1499"}
                                  </h4> */}

                                  <div className="d-flex bw_setup_Section_outer">
                                    <h4>
                                      {window.location.pathname.includes("-gbp") &&
                                        currency == "INR"
                                        ? "₹100000"
                                        : currency == "GBP"
                                          ? `£${setupFee}`
                                          : "$1499"}
                                    </h4>
                                    <select
                                      className="rounded-md bw_setup_Section"
                                      onChange={handleFeeChange}
                                      value={setupFee}
                                    >
                                      <option value={1099}>With Diamonds</option>
                                      <option value={1499}>With Gemstones</option>
                                    </select>
                                  </div>
                                </div>
                              </th>
                              <th className="bw_pricing_package_heading_lower text-center  w-20">
                                <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border  h-auto">
                                  <h6>Diamond Search Plugin</h6>
                                  <h4>
                                    <CountUp
                                      end={
                                        window.location.pathname.includes(
                                          "-gbp"
                                        ) && currency == "INR"
                                          ? 7000
                                          : currency == "GBP"
                                            ? 99
                                            : 150
                                      }
                                      duration={0}
                                      className="bwn_price_count_up"
                                      prefix={
                                        window.location.pathname.includes(
                                          "-gbp"
                                        ) && currency == "INR"
                                          ? "₹"
                                          : currency == "GBP"
                                            ? "£"
                                            : "$"
                                      }
                                      suffix="/"
                                    />
                                    <span>Month</span>
                                  </h4>
                                  <Button
                                    className="bw_btn_price_start"
                                    onClick={handleClick}
                                  >
                                    STARt
                                  </Button>
                                  <p>One-Time Setup Fees </p>
                                  <h4>
                                    {window.location.pathname.includes("-gbp") &&
                                      currency == "INR"
                                      ? "₹75000"
                                      : currency == "GBP"
                                        ? "£799"
                                        : "$999"}
                                  </h4>
                                </div>
                              </th>
                              <th className="bw_pricing_package_heading_lower text-center  w-20">
                                <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border  h-auto">
                                  <h6>Gemstone Search Plugin</h6>
                                  <h4>
                                    <CountUp
                                      end={
                                        window.location.pathname.includes(
                                          "-gbp"
                                        ) && currency == "INR"
                                          ? 7000
                                          : currency == "GBP"
                                            ? 99
                                            : 150
                                      }
                                      duration={0}
                                      className="bwn_price_count_up"
                                      prefix={
                                        window.location.pathname.includes(
                                          "-gbp"
                                        ) && currency == "INR"
                                          ? "₹"
                                          : currency == "GBP"
                                            ? "£"
                                            : "$"
                                      }
                                      suffix="/"
                                    />
                                    <span>Month</span>
                                  </h4>
                                  <Button
                                    className="bw_btn_price_start"
                                    onClick={handleClick}
                                  >
                                    STARt
                                  </Button>
                                  <p>One-Time Setup Fees </p>
                                  <h4>
                                    {window.location.pathname.includes("-gbp") &&
                                      currency == "INR"
                                      ? "₹75000"
                                      : currency == "GBP"
                                        ? "£799"
                                        : "$999"}
                                  </h4>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="bw_prcing_package bw_prcing_package">
                                Diamond supplier's live inventory integration*
                              </td>
                              <td>
                                <div>1 Supplier</div>
                              </td>
                              <td>
                                <div>1 Supplier</div>
                              </td>
                              <td>
                                <div>1 Supplier</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package">
                                Engagement Ring supplier's inventory integration*
                              </td>
                              <td>
                                <div>1 Supplier</div>
                              </td>
                              <td>
                                <div>N/A</div>
                              </td>
                              <td>
                                <div>N/A</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package">
                                Natural & Lab Grown Diamonds
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package">
                                Smart Diamond & Engagment Ring filters page
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package">
                                Functionality to add Setting or Loose Diamond
                                separately to the cart
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                              <td>
                                <div>N/A</div>
                              </td>
                              <td>
                                <div>N/A</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package">
                                Appointment form on product pages
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td className="bw_prcing_package">
                                Social Media Product Share
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package"> Drop Hint</td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Container>
                  <Container className="bw_custome_container_pricing">
                    {/* Mobile View of Table */}
                    <div className="bw_djwd_table_mobileview bw_prcing_seo">
                      <Tabs
                        value={valueInner}
                        onChange={handleChangenew}
                        aria-label="simple tabs example"
                        centered
                        className="mb-2 mb-sm-2 bwn_plugin_shopify_mobile_view_tab d_grid"
                      >
                        <Tab
                          label="Ring Builder Plugin"
                          {...a11yProps(0)}
                        />
                        <Tab
                          label="Diamond Search Plugin"
                          {...a11yProps(1)}
                        />
                         <Tab
                          label="Gemstone Search Plugin"
                          {...a11yProps(2)}
                        />
                      </Tabs>

                      <TabPanel value={valueInner} index={0}>
                        <Table bordered hover responsive className="mt-2 mb-3">
                          <thead>
                            <tr>
                              <th className="bw_table_had_bgColor_light w-50">
                                <h4 className="bw_table_column1_head">
                                  PACKAGE <br></br> FEATURES
                                </h4>
                              </th>
                              <th className="bw_table_had_bgColor w-50">
                                <h6>Ring Builder Plugin</h6>
                                <h4>
                                  <CountUp
                                    end={
                                      window.location.pathname.includes("-gbp") &&
                                        currency == "INR"
                                        ? 8000
                                        : currency == "GBP"
                                          ? 149
                                          : 200
                                    }
                                    duration={0}
                                    className="bwn_price_count_up"
                                    prefix={
                                      window.location.pathname.includes("-gbp") &&
                                        currency == "INR"
                                        ? "₹"
                                        : currency == "GBP"
                                          ? "£"
                                          : "$"
                                    }
                                    suffix="/"
                                  />
                                  <span>Month</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start bw_pricing_btn_white"
                                  onClick={handleClick}
                                >
                                  START
                                </Button>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="bw_prcing_package bw_prcing_package">
                                One-Time Setup Fees
                              </td>
                              <td>
                                <div className="d-flex bw_setup_Section_outer">
                                      {window.location.pathname.includes("-gbp") &&
                                        currency == "INR"
                                        ? "₹100000"
                                        : currency == "GBP"
                                          ? `£${setupFee}`
                                          : "$1499"}
                                    <select
                                      className="rounded-md bw_setup_Section"
                                      onChange={handleFeeChange}
                                      value={setupFee}
                                    >
                                      <option value={1099}>With Diamonds</option>
                                      <option value={1499}>With Gemstones</option>
                                    </select>
                                  </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package bw_prcing_package">
                                Diamond supplier's live inventory integration*
                              </td>
                              <td>
                                <div>1 Supplier</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package">
                                Engagement Ring supplier's inventory integration*
                              </td>
                              <td>
                                <div>1 Supplier</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package">
                                Natural & Lab Grown Diamonds
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package">
                                Smart Diamond & Engagment Ring filters page
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package">
                                Functionality to add Setting or Loose Diamond
                                separately to the cart
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package">
                                Appointment form on product pages
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td className="bw_prcing_package">
                                Social Media Product Share
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package"> Drop Hint</td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </TabPanel>
                      <TabPanel value={valueInner} index={1}>
                        <Table bordered hover responsive className="mt-2 mb-3">
                          <thead>
                            <tr>
                              <th className="bw_table_had_bgColor_light w-50">
                                <h4 className="bw_table_column1_head">
                                  PACKAGE <br></br> FEATURES
                                </h4>
                              </th>
                              <th className="bw_table_had_bgColor w-50">
                                <h6>Diamond Search Plugin</h6>
                                <h4>
                                  <CountUp
                                    end={
                                      window.location.pathname.includes("-gbp") &&
                                        currency == "INR"
                                        ? 7000
                                        : currency == "GBP"
                                          ? 99
                                          : 150
                                    }
                                    duration={0}
                                    className="bwn_price_count_up"
                                    prefix={
                                      window.location.pathname.includes("-gbp") &&
                                        currency == "INR"
                                        ? "₹"
                                        : currency == "GBP"
                                          ? "£"
                                          : "$"
                                    }
                                    suffix="/"
                                  />
                                  <span>Month</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start bw_pricing_btn_white"
                                  onClick={handleClick}
                                >
                                  STARt
                                </Button>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="bw_prcing_package bw_prcing_package">
                                One-Time Setup Fees
                              </td>
                              <td>
                                <div>
                                  {window.location.pathname.includes("-gbp") &&
                                    currency == "INR"
                                    ? "₹75000"
                                    : currency == "GBP"
                                      ? "£799"
                                      : "$999"}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package bw_prcing_package">
                                Diamond supplier's live inventory integration*
                              </td>
                              <td>
                                <div>1 Supplier</div>
                              </td>
                            </tr>

                            <tr>
                              <td className="bw_prcing_package">
                                Natural & Lab Grown Diamonds
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package">
                                Smart Diamond & Engagment Ring filters page
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td className="bw_prcing_package">
                                Appointment form on product pages
                              </td>

                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td className="bw_prcing_package">
                                Social Media Product Share
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package"> Drop Hint</td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </TabPanel>
                      <TabPanel value={valueInner} index={2}>
                        <Table bordered hover responsive className="mt-2 mb-3">
                          <thead>
                            <tr>
                              <th className="bw_table_had_bgColor_light w-50">
                                <h4 className="bw_table_column1_head">
                                  PACKAGE <br></br> FEATURES
                                </h4>
                              </th>
                              <th className="bw_table_had_bgColor w-50">
                                <h6>Diamond Search Plugin</h6>
                                <h4>
                                  <CountUp
                                    end={
                                      window.location.pathname.includes("-gbp") &&
                                        currency == "INR"
                                        ? 7000
                                        : currency == "GBP"
                                          ? 99
                                          : 150
                                    }
                                    duration={0}
                                    className="bwn_price_count_up"
                                    prefix={
                                      window.location.pathname.includes("-gbp") &&
                                        currency == "INR"
                                        ? "₹"
                                        : currency == "GBP"
                                          ? "£"
                                          : "$"
                                    }
                                    suffix="/"
                                  />
                                  <span>Month</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start bw_pricing_btn_white"
                                  onClick={handleClick}
                                >
                                  STARt
                                </Button>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="bw_prcing_package bw_prcing_package">
                                One-Time Setup Fees
                              </td>
                              <td>
                                <div>
                                  {window.location.pathname.includes("-gbp") &&
                                    currency == "INR"
                                    ? "₹75000"
                                    : currency == "GBP"
                                      ? "£799"
                                      : "$999"}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package bw_prcing_package">
                                Diamond supplier's live inventory integration*
                              </td>
                              <td>
                                <div>1 Supplier</div>
                              </td>
                            </tr>

                            <tr>
                              <td className="bw_prcing_package">
                                Natural & Lab Grown Diamonds
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package">
                                Smart Diamond & Engagment Ring filters page
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td className="bw_prcing_package">
                                Appointment form on product pages
                              </td>

                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td className="bw_prcing_package">
                                Social Media Product Share
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package"> Drop Hint</td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </TabPanel>
                    </div>
                    {/* Mobile View of Table ending */}
                    <Row>
                      <Col>
                        <ul className="bwn_b2b_note_container bw_green_djdinr_list_style">
                          <li>
                            A separate custom quotation will be shared for any
                            further requests in regards to design & development
                            not covered under the selected Shopify theme
                          </li>
                          <li>
                            A separate custom quotation will be sent for every
                            function not listed in your chosen plan.
                          </li>
                          <li>
                            {window.location.pathname.includes("-gbp") &&
                              currency == "INR"
                              ? "₹15000"
                              : "£199"}{" "}
                            will be charged against every additional diamond
                            supplier to be integrated.{" "}
                          </li>
                          <li>
                            {window.location.pathname.includes("-gbp") &&
                              currency == "INR"
                              ? "₹25000"
                              : "£199"}{" "}
                            onwards will be charged against every additional
                            Engagement Ring supplier to be integrated.
                          </li>
                          {window.location.pathname.includes("-gbp") &&
                            currency == "INR" ? (
                            <li>Above prices are exclusive of GST.</li>
                          ) : (
                            ""
                          )}
                        </ul>
                      </Col>
                    </Row>

                  </Container>
                </TabPanel>
                {/* Montly tab containe end */}
                {/* Annual tab containe start */}
                <TabPanel
                  value={value}
                  index={1}
                  className="bw_monthly_tab_outer_box"
                >
                  <Container className="bw_custome_container_pricing bw_pricing_tab_desktopview">
                    <Row className="w-100 m-auto">
                      <Col className="px-0">
                        <Table className="bw_pricing_montly_table">
                          <thead>
                            <tr className="border-top-0">
                              <th className="text-uppercase bw_pricing_package_heading align-middle w-20">
                                <div className="bw_mont_inner_heading_box bw_hair_salone_headingbox">
                                  <h6>
                                    Package <br /> Features{" "}
                                  </h6>
                                </div>
                              </th>

                              <th className="bw_pricing_package_heading_lower text-center  w-20">
                                <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border text-center">
                                  <h6>Ring Builder Plugin</h6>
                                  <h4>
                                    <CountUp
                                      end={
                                        window.location.pathname.includes(
                                          "-gbp"
                                        ) && currency == "INR"
                                          ? 88000
                                          : currency == "GBP"
                                            ? 1639
                                            : 2200
                                      }
                                      duration={0.5}
                                      className="bwn_price_count_up"
                                      prefix={
                                        window.location.pathname.includes(
                                          "-gbp"
                                        ) && currency == "INR"
                                          ? "₹"
                                          : currency == "GBP"
                                            ? "£"
                                            : "$"
                                      }
                                      suffix="/"
                                    />
                                    <span>Annum</span>
                                  </h4>
                                  <Button
                                    className="bw_btn_price_start"
                                    onClick={handleClick}
                                  >
                                    START
                                  </Button>
                                  <p>One-Time Setup Fees </p>
                                  <div className="d-flex bw_setup_Section_outer">
                                    <h4>
                                      {window.location.pathname.includes("-gbp") &&
                                        currency == "INR"
                                        ? "₹100000"
                                        : currency == "GBP"
                                          ? `£${setupFee}`
                                          : "$1499"}
                                    </h4>
                                    <select
                                      className="rounded-md bw_setup_Section"
                                      onChange={handleFeeChange}
                                      value={setupFee}
                                    >
                                      <option value={1099}>With Diamonds</option>
                                      <option value={1499}>With Gemstones</option>
                                    </select>
                                  </div>
                                </div>
                              </th>
                              <th className="bw_pricing_package_heading_lower text-center  w-20">
                                <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border  h-auto">
                                  <h6>Diamond Search Plugin</h6>
                                  <h4>
                                    <CountUp
                                      end={
                                        window.location.pathname.includes(
                                          "-gbp"
                                        ) && currency == "INR"
                                          ? 77000
                                          : currency == "GBP"
                                            ? 1089
                                            : 1650
                                      }
                                      duration={0.5}
                                      className="bwn_price_count_up"
                                      prefix={
                                        window.location.pathname.includes(
                                          "-gbp"
                                        ) && currency == "INR"
                                          ? "₹"
                                          : currency == "GBP"
                                            ? "£"
                                            : "$"
                                      }
                                      suffix="/"
                                    />
                                    <span>Annum</span>
                                  </h4>
                                  <Button
                                    className="bw_btn_price_start"
                                    onClick={handleClick}
                                  >
                                    STARt
                                  </Button>
                                  <p>One-Time Setup Fees </p>
                                  <h4>
                                    {window.location.pathname.includes("-gbp") &&
                                      currency == "INR"
                                      ? "₹75000"
                                      : currency == "GBP"
                                        ? "£799"
                                        : "$999"}
                                  </h4>
                                </div>
                              </th>
                              <th className="bw_pricing_package_heading_lower text-center  w-20">
                                <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border  h-auto">
                                  <h6>Gemstone Search Plugin</h6>
                                  <h4>
                                    <CountUp
                                      end={
                                        window.location.pathname.includes(
                                          "-gbp"
                                        ) && currency == "INR"
                                          ? 77000
                                          : currency == "GBP"
                                            ? 1089
                                            : 1650
                                      }
                                      duration={0.5}
                                      className="bwn_price_count_up"
                                      prefix={
                                        window.location.pathname.includes(
                                          "-gbp"
                                        ) && currency == "INR"
                                          ? "₹"
                                          : currency == "GBP"
                                            ? "£"
                                            : "$"
                                      }
                                      suffix="/"
                                    />
                                    <span>Annum</span>
                                  </h4>
                                  <Button
                                    className="bw_btn_price_start"
                                    onClick={handleClick}
                                  >
                                    STARt
                                  </Button>
                                  <p>One-Time Setup Fees </p>
                                  <h4>
                                    {window.location.pathname.includes("-gbp") &&
                                      currency == "INR"
                                      ? "₹75000"
                                      : currency == "GBP"
                                        ? "£799"
                                        : "$999"}
                                  </h4>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="bw_prcing_package bw_prcing_package">
                                Diamond supplier's live inventory integration*
                              </td>
                              <td>
                                <div>1 Supplier</div>
                              </td>
                              <td>
                                <div>1 Supplier</div>
                              </td>
                              <td>
                                <div>1 Supplier</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package">
                                Engagement Ring supplier's inventory integration*
                              </td>
                              <td>
                                <div>1 Supplier</div>
                              </td>
                              <td>
                                <div>N/A</div>
                              </td>
                              <td>
                                <div>N/A</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package">
                                Natural & Lab Grown Diamonds
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package">
                                Smart Diamond & Engagment Ring filters page
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package">
                                Functionality to add Setting or Loose Diamond
                                separately to the cart
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                              <td>
                                <div>N/A</div>
                              </td>
                              <td>
                                <div>N/A</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package">
                                Appointment form on product pages
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td className="bw_prcing_package">
                                Social Media Product Share
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package"> Drop Hint</td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Container>
                  <Container className="bw_custome_container_pricing">
                    {/* Mobile View of Table */}
                    <div className="bw_djwd_table_mobileview bw_prcing_seo">
                      <Tabs
                        value={valueInner}
                        onChange={handleChangenew}
                        aria-label="simple tabs example"
                        centered
                        className="mb-2 mb-sm-2 bwn_plugin_shopify_mobile_view_tab d_grid"
                      >
                        <Tab
                          label="Ring Builder Plugin"
                          {...a11yProps(0)}
                        />
                        <Tab
                          label="Diamond Search Plugin"
                          {...a11yProps(1)}
                        />
                         <Tab
                          label="Gemstone Search Plugin"
                          {...a11yProps(1)}
                        />
                      </Tabs>

                      <TabPanel value={valueInner} index={0}>
                        <Table bordered hover responsive className="mt-2 mb-3">
                          <thead>
                            <tr>
                              <th className="bw_table_had_bgColor_light w-50">
                                <h4 className="bw_table_column1_head">
                                  PACKAGE <br></br> FEATURES
                                </h4>
                              </th>
                              <th className="bw_table_had_bgColor w-50">
                                <h6>Ring Builder Plugin</h6>
                                <h4>
                                  <CountUp
                                    end={
                                      window.location.pathname.includes("-gbp") &&
                                        currency == "INR"
                                        ? 88000
                                        : currency == "GBP"
                                          ? 1639
                                          : 2200
                                    }
                                    duration={0.5}
                                    className="bwn_price_count_up"
                                    prefix={
                                      window.location.pathname.includes("-gbp") &&
                                        currency == "INR"
                                        ? "₹"
                                        : currency == "GBP"
                                          ? "£"
                                          : "$"
                                    }
                                    suffix="/"
                                  />
                                  <span>Annum</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start bw_pricing_btn_white"
                                  onClick={handleClick}
                                >
                                  STARt
                                </Button>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="bw_prcing_package bw_prcing_package">
                                One-Time Setup Fees
                              </td>
                              <td>
                                <div className="d-flex bw_setup_Section_outer">
                                      {window.location.pathname.includes("-gbp") &&
                                        currency == "INR"
                                        ? "₹100000"
                                        : currency == "GBP"
                                          ? `£${setupFee}`
                                          : "$1499"}
                                    <select
                                      className="rounded-md bw_setup_Section"
                                      onChange={handleFeeChange}
                                      value={setupFee}
                                    >
                                      <option value={1099}>With Diamonds</option>
                                      <option value={1499}>With Gemstones</option>
                                    </select>
                                  </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package bw_prcing_package">
                                Diamond supplier's live inventory integration*
                              </td>
                              <td>
                                <div>1 Supplier</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package">
                                Engagement Ring supplier's inventory integration*
                              </td>
                              <td>
                                <div>1 Supplier</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package">
                                Natural & Lab Grown Diamonds
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package">
                                Smart Diamond & Engagment Ring filters page
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package">
                                Functionality to add Setting or Loose Diamond
                                separately to the cart
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package">
                                Appointment form on product pages
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td className="bw_prcing_package">
                                Social Media Product Share
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package"> Drop Hint</td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </TabPanel>
                      <TabPanel value={valueInner} index={1}>
                        <Table bordered hover responsive className="mt-2 mb-3">
                          <thead>
                            <tr>
                              <th className="bw_table_had_bgColor_light w-50">
                                <h4 className="bw_table_column1_head">
                                  PACKAGE <br></br> FEATURES
                                </h4>
                              </th>
                              <th className="bw_table_had_bgColor w-50">
                                <h6>Diamond Search Plugin</h6>
                                <h4>
                                  <CountUp
                                    end={
                                      window.location.pathname.includes("-gbp") &&
                                        currency == "INR"
                                        ? 77000
                                        : currency == "GBP"
                                          ? 1089
                                          : 3850
                                    }
                                    duration={0.5}
                                    className="bwn_price_count_up"
                                    prefix={
                                      window.location.pathname.includes("-gbp") &&
                                        currency == "INR"
                                        ? "₹"
                                        : currency == "GBP"
                                          ? "£"
                                          : "$"
                                    }
                                    suffix="/"
                                  />
                                  <span>Annum</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start bw_pricing_btn_white"
                                  onClick={handleClick}
                                >
                                  STARt
                                </Button>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="bw_prcing_package bw_prcing_package">
                                One-Time Setup Fees
                              </td>
                              <td>
                                <div>
                                  {window.location.pathname.includes("-gbp") &&
                                    currency == "INR"
                                    ? "₹75000"
                                    : currency == "GBP"
                                      ? "£799"
                                      : " $999"}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package bw_prcing_package">
                                Diamond supplier's live inventory integration*
                              </td>
                              <td>
                                <div>1 Supplier</div>
                              </td>
                            </tr>

                            <tr>
                              <td className="bw_prcing_package">
                                Natural & Lab Grown Diamonds
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package">
                                Smart Diamond & Engagment Ring filters page
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td className="bw_prcing_package">
                                Appointment form on product pages
                              </td>

                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td className="bw_prcing_package">
                                Social Media Product Share
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package"> Drop Hint</td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </TabPanel>
                      <TabPanel value={valueInner} index={2}>
                        <Table bordered hover responsive className="mt-2 mb-3">
                          <thead>
                            <tr>
                              <th className="bw_table_had_bgColor_light w-50">
                                <h4 className="bw_table_column1_head">
                                  PACKAGE <br></br> FEATURES
                                </h4>
                              </th>
                              <th className="bw_table_had_bgColor w-50">
                                <h6>Gemstone Search Plugin</h6>
                                <h4>
                                  <CountUp
                                    end={
                                      window.location.pathname.includes("-gbp") &&
                                        currency == "INR"
                                        ? 77000
                                        : currency == "GBP"
                                          ? 1089
                                          : 3850
                                    }
                                    duration={0.5}
                                    className="bwn_price_count_up"
                                    prefix={
                                      window.location.pathname.includes("-gbp") &&
                                        currency == "INR"
                                        ? "₹"
                                        : currency == "GBP"
                                          ? "£"
                                          : "$"
                                    }
                                    suffix="/"
                                  />
                                  <span>Annum</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start bw_pricing_btn_white"
                                  onClick={handleClick}
                                >
                                  STARt
                                </Button>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="bw_prcing_package bw_prcing_package">
                                One-Time Setup Fees
                              </td>
                              <td>
                                <div>
                                  {window.location.pathname.includes("-gbp") &&
                                    currency == "INR"
                                    ? "₹75000"
                                    : currency == "GBP"
                                      ? "£799"
                                      : " $999"}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package bw_prcing_package">
                                Diamond supplier's live inventory integration*
                              </td>
                              <td>
                                <div>1 Supplier</div>
                              </td>
                            </tr>

                            <tr>
                              <td className="bw_prcing_package">
                                Natural & Lab Grown Diamonds
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package">
                                Smart Diamond & Engagment Ring filters page
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td className="bw_prcing_package">
                                Appointment form on product pages
                              </td>

                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td className="bw_prcing_package">
                                Social Media Product Share
                              </td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bw_prcing_package"> Drop Hint</td>
                              <td>
                                <div className="bw_pricing_checkicon">
                                  <FiCheck />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </TabPanel>
                    </div>
                    {/* Mobile View of Table ending */}
                    <Row>
                      <Col>
                        <ul className="bwn_b2b_note_container bw_green_djdinr_list_style">
                          <li>
                            A separate custom quotation will be shared for any
                            further requests in regards to design & development
                            not covered under the selected Shopify theme
                          </li>
                          <li>
                            A separate custom quotation will be sent for every
                            function not listed in your chosen plan.
                          </li>
                          <li>
                            {window.location.pathname.includes("-gbp") &&
                              currency == "INR"
                              ? "₹15000"
                              : "$250"}{" "}
                            will be charged against every additional diamond
                            supplier to be integrated.{" "}
                          </li>
                          <li>
                            {window.location.pathname.includes("-gbp") &&
                              currency == "INR"
                              ? "₹25000"
                              : "$500"}{" "}
                            onwards will be charged against every additional
                            Engagement Ring supplier to be integrated.
                          </li>
                          {window.location.pathname.includes("-gbp") &&
                            currency == "INR" ? (
                            <li>Above prices are exclusive of GST.</li>
                          ) : (
                            ""
                          )}
                        </ul>
                      </Col>
                    </Row>

                  </Container>
                </TabPanel>

                {/* Annual tab containe end */}
              </Container>
            </div>
          </TabPanel>
          <TabPanel
            value={value1}
            index={1}
            className="bw_monthly_tab_outer_box"
          >
            <div className="bw_pricing_tabs_section">
              <Container className="bw_custome_container_pricing position-relative">
                <div className="bw_pricing_tabs_box bw_only_month_tab text-center position-relative">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    centered
                  >
                    <Tab label="Monthly" {...a11yProps(0)} />
                    <Tab label="Annually" {...a11yProps(1)} />
                  </Tabs>
                </div>
                <div className="bwn_first_month_free_text text-center">
                  <div className="position-relative">
                    <button className="bwn_get_on_month_free_button">
                      Annually Get 1 Month Free
                    </button>
                    {/* <Image src={ArrowImpImg}></Image> */}
                  </div>
                </div>
                {/* Montly tab containe start */}
                {/* {window.location.pathname.includes("-gbp") ? <Row className="w-100 m-0">
               <Col xl={1} lg={2} md={12} xs={10} className="inr_input_main">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Currency</InputLabel>
                    <Select
                      size={"small"}
                      inputProps={{ MenuProps: { disableScrollLock: true } }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={currency}
                      label="Currency"
                      onChange={(e) => setCurrency(e.target.value)}
                    >
                      <MenuItem value={"INR"}>INR</MenuItem>
                      <MenuItem value={"USD"}>USD</MenuItem>
                      <MenuItem value={"GBP"}>GBP</MenuItem>
                    </Select>
                  </FormControl>

                </Col>

              </Row> : ""} */}
                <TabPanel
                  value={value}
                  index={0}
                  className="bw_monthly_tab_outer_box"
                >
                  <Container className="bw_custome_container_pricing bw_pricing_tab_desktopview">
                    <Row className="w-100 m-auto">
                      <Col className="px-0">
                        <Table className="bw_pricing_montly_table">
                          <thead>
                            <tr className="border-top-0">
                              <th className="text-uppercase bw_pricing_package_heading align-middle w-20">
                                <div className="bw_mont_inner_heading_box bw_hair_salone_headingbox">
                                  <h6>
                                    Package <br /> Features{" "}
                                  </h6>
                                </div>
                              </th>

                              <th className="bw_pricing_package_heading_lower text-center  w-20">
                                <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border text-center">
                                  <h6>Band Builder Plugin</h6>
                                  <h4>
                                    <CountUp
                                      end={
                                        window.location.pathname.includes(
                                          "-gbp"
                                        ) && currency == "INR"
                                          ? 8000
                                          : currency == "GBP"
                                            ? 99
                                            : 200
                                      }
                                      duration={0}
                                      className="bwn_price_count_up"
                                      prefix={
                                        window.location.pathname.includes(
                                          "-gbp"
                                        ) && currency == "INR"
                                          ? "₹"
                                          : currency == "GBP"
                                            // ? "£"
                                            ? "$"
                                            : "$"
                                      }
                                      suffix="/"
                                    />
                                    <span>Month</span>
                                  </h4>
                                  <Button
                                    className="bw_btn_price_start"
                                    onClick={handleClick}
                                  >
                                    START
                                  </Button>
                                  <p>One-Time Setup Fees </p>
                                  <h4>
                                    {window.location.pathname.includes("-gbp") &&
                                      currency == "INR"
                                      ? "₹100000"
                                      : currency == "GBP"
                                        // ? "£1099"
                                        ? "$499"
                                        : "$1499"}
                                  </h4>
                                </div>
                              </th>
                              {/* <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border  h-auto">
                                <h6>Diamond Search Plugin</h6>
                                <h4>
                                  <CountUp
                                    end={
                                      window.location.pathname.includes(
                                        "-gbp"
                                      ) && currency == "INR"
                                        ? 7000
                                        : currency == "GBP"
                                        ? 99
                                        : 150
                                    }
                                    duration={0}
                                    className="bwn_price_count_up"
                                    prefix={
                                      window.location.pathname.includes(
                                        "-gbp"
                                      ) && currency == "INR"
                                        ? "₹"
                                        : currency == "GBP"
                                        ? "£"
                                        : "$"
                                    }
                                    suffix="/"
                                  />
                                  <span>Month</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  STARt
                                </Button>
                                <p>One-Time Setup Fees </p>
                                <h4>
                                  {window.location.pathname.includes("-gbp") &&
                                  currency == "INR"
                                    ? "₹75000"
                                    : currency == "GBP"
                                    ? "£799"
                                    : "$999"}
                                </h4>
                              </div>
                            </th> */}
                            </tr>
                          </thead>
                          {/* <tbody>
                          <tr>
                            <td className="bw_prcing_package bw_prcing_package">
                              Diamond supplier's live inventory integration*
                            </td>
                            <td>
                              <div>1 Supplier</div>
                            </td>
                            <td>
                              <div>1 Supplier</div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Engagement Ring supplier's inventory integration*
                            </td>
                            <td>
                              <div>1 Supplier</div>
                            </td>
                            <td>
                              <div>N/A</div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Natural & Lab Grown Diamonds
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Smart Diamond & Engagment Ring filters page
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Functionality to add Setting or Loose Diamond
                              separately to the cart
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div>N/A</div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Appointment form on product pages
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td className="bw_prcing_package">
                              Social Media Product Share
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package"> Drop Hint</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody> */}
                        </Table>
                        <div className="band_bilder_video">
                          <video height="100%" loop="true" width="100%" muted="" playsinline="" autoplay="true">
                            <source src={BandBuilderVideo} type="video/mp4" />
                            <source src={BandBuilderVideo} type="video/ogg" />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <Container className="bw_custome_container_pricing">
                    {/* Mobile View of Table */}
                    <div className="bw_djwd_table_mobileview bw_prcing_seo">
                      <Tabs
                        value={valueInner}
                        onChange={handleChangenew}
                        aria-label="simple tabs example"
                        centered
                        className="mb-2 mb-sm-2 bwn_plugin_shopify_mobile_view_tab"
                      >
                        <Tab
                          label="Band Builder Plugin"
                          {...a11yProps(0)}
                        />
                        {/* <Tab
                        label="Diamond Search Plugin"
                        {...a11yProps(1)}
                      /> */}
                      </Tabs>

                      <TabPanel value={valueInner} index={0}>
                        <Table bordered hover responsive className="mt-2 mb-3">
                          <thead>
                            <tr>
                              <th className="bw_table_had_bgColor_light w-50">
                                <h4 className="bw_table_column1_head">
                                  PACKAGE <br></br> FEATURES
                                </h4>
                              </th>
                              <th className="bw_table_had_bgColor w-50">
                                <h6>Band Builder Plugin</h6>
                                <h4>
                                  <CountUp
                                    end={
                                      window.location.pathname.includes("-gbp") &&
                                        currency == "INR"
                                        ? 8000
                                        : currency == "GBP"
                                          ? 99
                                          : 200
                                    }
                                    duration={0}
                                    className="bwn_price_count_up"
                                    prefix={
                                      window.location.pathname.includes("-gbp") &&
                                        currency == "INR"
                                        ? "₹"
                                        : currency == "GBP"
                                          // ? "£"
                                          ? "$"
                                          : "$"
                                    }
                                    suffix="/"
                                  />
                                  <span>Month</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start bw_pricing_btn_white"
                                  onClick={handleClick}
                                >
                                  START
                                </Button>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="bw_prcing_package bw_prcing_package">
                                One-Time Setup Fees
                              </td>
                              <td>
                                <div>
                                  {window.location.pathname.includes("-gbp") &&
                                    currency == "INR"
                                    ? "₹100000"
                                    : currency == "GBP"
                                      // ? "£1099"
                                      ? "$499"
                                      : "$1499"}
                                </div>
                              </td>
                            </tr>
                            {/* <tr>
                            <td className="bw_prcing_package bw_prcing_package">
                              Diamond supplier's live inventory integration*
                            </td>
                            <td>
                              <div>1 Supplier</div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Engagement Ring supplier's inventory integration*
                            </td>
                            <td>
                              <div>1 Supplier</div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Natural & Lab Grown Diamonds
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Smart Diamond & Engagment Ring filters page
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Functionality to add Setting or Loose Diamond
                              separately to the cart
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Appointment form on product pages
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td className="bw_prcing_package">
                              Social Media Product Share
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package"> Drop Hint</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr> */}
                          </tbody>
                        </Table>
                        <div className="band_bilder_video">
                          <video height="100%" loop="true" width="100%" muted="" playsinline="" autoplay="true">
                            <source src={BandBuilderVideo} type="video/mp4" />
                            <source src={BandBuilderVideo} type="video/ogg" />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      </TabPanel>
                      {/* <TabPanel value={valueInner} index={1}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>Diamond Search Plugin</h6>
                              <h4>
                                <CountUp
                                  end={
                                    window.location.pathname.includes("-gbp") &&
                                    currency == "INR"
                                      ? 7000
                                      : currency == "GBP"
                                      ? 99
                                      : 150
                                  }
                                  duration={0}
                                  className="bwn_price_count_up"
                                  prefix={
                                    window.location.pathname.includes("-gbp") &&
                                    currency == "INR"
                                      ? "₹"
                                      : currency == "GBP"
                                      ? "£"
                                      : "$"
                                  }
                                  suffix="/"
                                />
                                <span>Month</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package bw_prcing_package">
                              One-Time Setup Fees
                            </td>
                            <td>
                              <div>
                                {window.location.pathname.includes("-gbp") &&
                                currency == "INR"
                                  ? "₹75000"
                                  : currency == "GBP"
                                  ? "£799"
                                  : "$999"}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package bw_prcing_package">
                              Diamond supplier's live inventory integration*
                            </td>
                            <td>
                              <div>1 Supplier</div>
                            </td>
                          </tr>
                         
                          <tr>
                            <td className="bw_prcing_package">
                              Natural & Lab Grown Diamonds
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Smart Diamond & Engagment Ring filters page
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        
                          <tr>
                            <td className="bw_prcing_package">
                              Appointment form on product pages
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td className="bw_prcing_package">
                              Social Media Product Share
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package"> Drop Hint</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel> */}
                    </div>
                    {/* Mobile View of Table ending */}
                    {/* <Row>
                    <Col>
                      <ul className="bwn_b2b_note_container bw_green_djdinr_list_style">
                        <li>
                          A separate custom quotation will be shared for any
                          further requests in regards to design & development
                          not covered under the selected Shopify theme
                        </li>
                        <li>
                          A separate custom quotation will be sent for every
                          function not listed in your chosen plan.
                        </li>
                        <li>
                          {window.location.pathname.includes("-gbp") &&
                          currency == "INR"
                            ? "₹15000"
                            : "£199"}{" "}
                          will be charged against every additional diamond
                          supplier to be integrated.{" "}
                        </li>
                        <li>
                          {window.location.pathname.includes("-gbp") &&
                          currency == "INR"
                            ? "₹25000"
                            : "£199"}{" "}
                          onwards will be charged against every additional
                          Engagement Ring supplier to be integrated.
                        </li>
                        {window.location.pathname.includes("-gbp") &&
                        currency == "INR" ? (
                          <li>Above prices are exclusive of GST.</li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </Col>
                  </Row> */}

                  </Container>
                </TabPanel>
                {/* Montly tab containe end */}
                {/* Annual tab containe start */}
                <TabPanel
                  value={value}
                  index={1}
                  className="bw_monthly_tab_outer_box"
                >
                  <Container className="bw_custome_container_pricing bw_pricing_tab_desktopview">
                    <Row className="w-100 m-auto">
                      <Col className="px-0">
                        <Table className="bw_pricing_montly_table">
                          <thead>
                            <tr className="border-top-0">
                              <th className="text-uppercase bw_pricing_package_heading align-middle w-20">
                                <div className="bw_mont_inner_heading_box bw_hair_salone_headingbox">
                                  <h6>
                                    Package <br /> Features{" "}
                                  </h6>
                                </div>
                              </th>

                              <th className="bw_pricing_package_heading_lower text-center  w-20">
                                <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border text-center">
                                  <h6>Band Builder Plugin</h6>
                                  <h4>
                                    <CountUp
                                      end={
                                        window.location.pathname.includes(
                                          "-gbp"
                                        ) && currency == "INR"
                                          ? 88000
                                          : currency == "GBP"
                                            ? 1089
                                            : 2200
                                      }
                                      duration={0.5}
                                      className="bwn_price_count_up"
                                      prefix={
                                        window.location.pathname.includes(
                                          "-gbp"
                                        ) && currency == "INR"
                                          ? "₹"
                                          : currency == "GBP"
                                            // ? "£"
                                            ? "$"
                                            : "$"
                                      }
                                      suffix="/"
                                    />
                                    <span>Annum</span>
                                  </h4>
                                  <Button
                                    className="bw_btn_price_start"
                                    onClick={handleClick}
                                  >
                                    START
                                  </Button>
                                  <p>One-Time Setup Fees </p>
                                  <h4>
                                    {window.location.pathname.includes("-gbp") &&
                                      currency == "INR"
                                      ? "₹100000"
                                      : currency == "GBP"
                                        // ? "£1099"
                                        ? "$499"
                                        : "$1499"}
                                  </h4>
                                </div>
                              </th>
                              {/* <th className="bw_pricing_package_heading_lower text-center  w-20 d-none">
                              <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border  h-auto">
                                <h6>Diamond Search Plugin</h6>
                                <h4>
                                  <CountUp
                                    end={
                                      window.location.pathname.includes(
                                        "-gbp"
                                      ) && currency == "INR"
                                        ? 77000
                                        : currency == "GBP"
                                        ? 1089
                                        : 1650
                                    }
                                    duration={0.5}
                                    className="bwn_price_count_up"
                                    prefix={
                                      window.location.pathname.includes(
                                        "-gbp"
                                      ) && currency == "INR"
                                        ? "₹"
                                        : currency == "GBP"
                                        ? "£"
                                        : "$"
                                    }
                                    suffix="/"
                                  />
                                  <span>Annum</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  STARt
                                </Button>
                                <p>One-Time Setup Fees </p>
                                <h4>
                                  {window.location.pathname.includes("-gbp") &&
                                  currency == "INR"
                                    ? "₹75000"
                                    : currency == "GBP"
                                    ? "£799"
                                    : "$999"}
                                </h4>
                              </div>
                            </th> */}
                            </tr>
                          </thead>
                          {/* <tbody>
                          <tr>
                            <td className="bw_prcing_package bw_prcing_package">
                              Diamond supplier's live inventory integration*
                            </td>
                            <td>
                              <div>1 Supplier</div>
                            </td>
                            <td>
                              <div>1 Supplier</div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Engagement Ring supplier's inventory integration*
                            </td>
                            <td>
                              <div>1 Supplier</div>
                            </td>
                            <td>
                              <div>N/A</div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Natural & Lab Grown Diamonds
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Smart Diamond & Engagment Ring filters page
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Functionality to add Setting or Loose Diamond
                              separately to the cart
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div>N/A</div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Appointment form on product pages
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td className="bw_prcing_package">
                              Social Media Product Share
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package"> Drop Hint</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody> */}
                        </Table>
                        <div className="band_bilder_video">
                          <video height="100%" loop="true" width="100%" muted="" playsinline="" autoplay="true">
                            <source src={BandBuilderVideo} type="video/mp4" />
                            <source src={BandBuilderVideo} type="video/ogg" />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <Container className="bw_custome_container_pricing">
                    {/* Mobile View of Table */}
                    <div className="bw_djwd_table_mobileview bw_prcing_seo">
                      <Tabs
                        value={valueInner}
                        onChange={handleChangenew}
                        aria-label="simple tabs example"
                        centered
                        className="mb-2 mb-sm-2 bwn_plugin_shopify_mobile_view_tab"
                      >
                        <Tab
                          label="Band Builder Plugin"
                          {...a11yProps(0)}
                        />
                        {/* <Tab
                        label="Diamond Search Plugin"
                        {...a11yProps(1)}
                      /> */}
                      </Tabs>

                      <TabPanel value={valueInner} index={0}>
                        <Table bordered hover responsive className="mt-2 mb-3">
                          <thead>
                            <tr>
                              <th className="bw_table_had_bgColor_light w-50">
                                <h4 className="bw_table_column1_head">
                                  PACKAGE <br></br> FEATURES
                                </h4>
                              </th>
                              <th className="bw_table_had_bgColor w-50">
                                <h6>Band Builder Plugin</h6>
                                <h4>
                                  <CountUp
                                    end={
                                      window.location.pathname.includes("-gbp") &&
                                        currency == "INR"
                                        ? 88000
                                        : currency == "GBP"
                                          ? 1089
                                          : 2200
                                    }
                                    duration={0.5}
                                    className="bwn_price_count_up"
                                    prefix={
                                      window.location.pathname.includes("-gbp") &&
                                        currency == "INR"
                                        ? "₹"
                                        : currency == "GBP"
                                          // ? "£"
                                          ? "$"
                                          : "$"
                                    }
                                    suffix="/"
                                  />
                                  <span>Annum</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start bw_pricing_btn_white"
                                  onClick={handleClick}
                                >
                                  STARt
                                </Button>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="bw_prcing_package bw_prcing_package">
                                One-Time Setup Fees
                              </td>
                              <td>
                                <div>
                                  {window.location.pathname.includes("-gbp") &&
                                    currency == "INR"
                                    ? "₹100000"
                                    : currency == "GBP"
                                      // ? "£1099"
                                      ? "$499"
                                      : "$1499"}
                                </div>
                              </td>
                            </tr>
                            {/* <tr>
                            <td className="bw_prcing_package bw_prcing_package">
                              Diamond supplier's live inventory integration*
                            </td>
                            <td>
                              <div>1 Supplier</div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Engagement Ring supplier's inventory integration*
                            </td>
                            <td>
                              <div>1 Supplier</div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Natural & Lab Grown Diamonds
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Smart Diamond & Engagment Ring filters page
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Functionality to add Setting or Loose Diamond
                              separately to the cart
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Appointment form on product pages
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td className="bw_prcing_package">
                              Social Media Product Share
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package"> Drop Hint</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr> */}
                          </tbody>
                        </Table>
                        <div className="band_bilder_video">
                          <video height="100%" loop="true" width="100%" muted="" playsinline="" autoplay="true">
                            <source src={BandBuilderVideo} type="video/mp4" />
                            <source src={BandBuilderVideo} type="video/ogg" />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      </TabPanel>
                      {/* <TabPanel value={valueInner} index={1}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>Diamond Search Plugin</h6>
                              <h4>
                                <CountUp
                                  end={
                                    window.location.pathname.includes("-gbp") &&
                                    currency == "INR"
                                      ? 77000
                                      : currency == "GBP"
                                      ? 1089
                                      : 3850
                                  }
                                  duration={0.5}
                                  className="bwn_price_count_up"
                                  prefix={
                                    window.location.pathname.includes("-gbp") &&
                                    currency == "INR"
                                      ? "₹"
                                      : currency == "GBP"
                                      ? "£"
                                      : "$"
                                  }
                                  suffix="/"
                                />
                                <span>Annum</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package bw_prcing_package">
                              One-Time Setup Fees
                            </td>
                            <td>
                              <div>
                                {window.location.pathname.includes("-gbp") &&
                                currency == "INR"
                                  ? "₹75000"
                                  : currency == "GBP"
                                  ? "£799"
                                  : " $999"}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package bw_prcing_package">
                              Diamond supplier's live inventory integration*
                            </td>
                            <td>
                              <div>1 Supplier</div>
                            </td>
                          </tr>
                         
                          <tr>
                            <td className="bw_prcing_package">
                              Natural & Lab Grown Diamonds
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Smart Diamond & Engagment Ring filters page
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                         
                          <tr>
                            <td className="bw_prcing_package">
                              Appointment form on product pages
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td className="bw_prcing_package">
                              Social Media Product Share
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package"> Drop Hint</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel> */}
                    </div>
                    {/* Mobile View of Table ending */}
                    {/* <Row>
                    <Col>
                      <ul className="bwn_b2b_note_container bw_green_djdinr_list_style">
                        <li>
                          A separate custom quotation will be shared for any
                          further requests in regards to design & development
                          not covered under the selected Shopify theme
                        </li>
                        <li>
                          A separate custom quotation will be sent for every
                          function not listed in your chosen plan.
                        </li>
                        <li>
                          {window.location.pathname.includes("-gbp") &&
                          currency == "INR"
                            ? "₹15000"
                            : "$250"}{" "}
                          will be charged against every additional diamond
                          supplier to be integrated.{" "}
                        </li>
                        <li>
                          {window.location.pathname.includes("-gbp") &&
                          currency == "INR"
                            ? "₹25000"
                            : "$500"}{" "}
                          onwards will be charged against every additional
                          Engagement Ring supplier to be integrated.
                        </li>
                        {window.location.pathname.includes("-gbp") &&
                        currency == "INR" ? (
                          <li>Above prices are exclusive of GST.</li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </Col>
                  </Row> */}

                  </Container>
                </TabPanel>

                {/* Annual tab containe end */}
              </Container>
            </div>
          </TabPanel>

        </div>
      </section>
    </>
  );
};

export default React.memo(ShopifyPluginGBP);
