import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import "../../Assets/css/webDevelopmentDesign.css";
import { Container, Row, Col, Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import WddImageSeo from "../../Assets/images/seo-img/seo-main-banenr.png";
import WddImage2 from "../../Assets/images/web-development-and-design/wdd2.webp";
import ServiceIcon from "../../Assets/images/web-development-and-design/green-services-icon.webp";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SeoImg1 from '../../Assets/images/seo-img/seo1.png'
import SeoImg2 from '../../Assets/images/seo-img/seo2.png'
import SeoImg3 from '../../Assets/images/seo-img/seo3.png'
import SeoImg4 from '../../Assets/images/seo-img/seo4.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import prevArrowImg from '../../Assets/images/home/left_icon.png'
import nextArrowImg from '../../Assets/images/home/right_arrow.png'
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import axios from "axios";
import { baseURL, postHeader } from "../../Helpers/request";
import swal from "sweetalert";
import "../../Assets/css/Sweetalert.css";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "react-js-loader";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useHistory, Link, useLocation } from "react-router-dom/cjs/react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";


import ServicesImg1 from "../../Assets/images/seo-img/seo-services-icons/local-seo.svg";
import ServicesImg2 from "../../Assets/images/seo-img/seo-services-icons/e-commerce.svg";
import ServicesImg3 from "../../Assets/images/seo-img/seo-services-icons/shpoify-seo.svg";
import ServicesImg4 from "../../Assets/images/seo-img/seo-services-icons/content-optimization.svg";
import ServicesImg5 from "../../Assets/images/seo-img/seo-services-icons/keyword-research.svg";
import ServicesImg6 from "../../Assets/images/seo-img/seo-services-icons/conversion-rate-optimization.svg";
import ServicesImg7 from "../../Assets/images/seo-img/seo-services-icons/link-building.svg";
import ServicesImg8 from "../../Assets/images/seo-img/seo-services-icons/voice-search-optimization.svg";
import ServicesImg9 from "../../Assets/images/seo-img/seo-services-icons/new-websites.svg";

import Role1 from "../../Assets/images/seo-img/role-of-seo/increased-website-traffic.svg";
import Role2 from "../../Assets/images/seo-img/role-of-seo/targeted-audience-engagement.svg";
import Role3 from "../../Assets/images/seo-img/role-of-seo/improvev-user-experience.svg";
import Role4 from "../../Assets/images/seo-img/role-of-seo/higher-conversion-rates.svg";
import Role5 from "../../Assets/images/seo-img/role-of-seo/cost-effective-marketing.svg";
import Role6 from "../../Assets/images/seo-img/role-of-seo/building-brand-credibility.svg";
import { useEffect } from "react";

const SearchEngineOptimization = () => {

  const history = useHistory();
  const captchaRef = useRef(null);
  const sectionRef = useRef(null);

  const scrollToSection = () => {
    sectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };


  const [selectedDate, setSelectedDate] = React.useState("");
  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const [business, setBusiness] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [time, setTime] = React.useState("10:00");
  const [timefeild, setTimefeild] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [timezone, setTimezone] = React.useState("");
  const [load, setLoad] = React.useState(false);
  const [captcha, setCaptcha] = React.useState(true);
  const [services, setServices] = useState([]);
  const [expanded, setExpanded] = React.useState("panel2");

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  var settingsSeo = {
    dots: false,
    arrows: true,
    prevArrow: <img src={prevArrowImg} />,
    nextArrow: <img src={nextArrowImg} />,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const seoStrategy = [
    {
      title: "Set A Goal",
      description: "Define objectives like increased organic traffic, better rankings, or higher conversions to guide the strategy."
    },
    {
      title: "Conduct Competitor Analysis",
      description: "Examine competitors’ SEO tactics, keywords, and content to uncover opportunities and stay ahead in your industry."
    },
    {
      title: "Keyword Mapping",
      description: "Assign targeted keywords to specific pages, ensuring every page addresses relevant search intent for better rankings."
    },
    {
      title: "Content Development",
      description: "Create fresh, engaging, and valuable content, such as blogs, infographics, or videos tailored to your audience’s needs."
    },
    {
      title: "User Experience (UX) Improvement",
      description: "Enhance website navigation, design, and responsiveness to retain visitors and reduce bounce rates."
    },
    {
      title: "Structured Data Implementation",
      description: "Use schema markup to help search engines better understand your content and improve visibility in rich results."
    },
    {
      title: "Local and Voice Search Focus",
      description: "Optimise for location-based searches and conversational queries to capture local and voice-driven audiences."
    },
    {
      title: "Monitoring and Optimisation",
      description: "Regularly analyze data from tools like Google Analytics and Search Console, adjusting the strategy to maximize performance."
    }
  ];
  const seoBenefits = [
    {
      heading: "Improves Online Visibility",
      content: "SEO ensures your website ranks higher on search engines, making it easier for potential customers to find you. Increased visibility boosts website traffic, allowing your business to reach a broader audience and gain more exposure."
    },
    {
      heading: "Drives Targeted Traffic",
      content: "By targeting the right keywords, SEO connects you with users actively searching for your products or services. This ensures that the traffic to your website is relevant, increasing the likelihood of conversions and sales."
    },
    {
      heading: "Builds Credibility and Trust",
      content: "Appearing on the first page of search results establishes your business as an authority in your industry. A well-optimized website with valuable content builds trust, encouraging customers to choose your business over competitors."
    },
    {
      heading: "Provides Long-Term Results",
      content: "Unlike paid ads, SEO delivers sustainable growth over time. With consistent optimization, your website attracts organic traffic and generates leads, offering a cost-effective way to drive long-term business success."
    }
  ];
  const seoServices = [
    {
      title: "Local SEO",
      description: "Boost your local business visibility with tailored SEO strategies. We optimize your website for local searches, ensuring customers nearby easily find your services, increasing footfall, and enhancing your community presence.",
      servicesImg: ServicesImg1,
      url: "#"
    },
    {
      title: "E-commerce SEO",
      description: "Drive online sales with strategic e-commerce SEO. Optimize product pages, enhance user experience, and target shoppers with relevant keywords to improve rankings. Moreover, you can attract customers and increase revenue for your online store.",
      servicesImg: ServicesImg2,
      url: "#"
    },
    {
      title: "Shopify SEO",
      description: "Maximize your Shopify store’s potential. From technical optimization to targeting high-converting keywords, we enhance your visibility, drive traffic, and ensure your store achieves top performance in search results.",
      servicesImg: ServicesImg3,
      url: "#"
    },
    {
      title: "Content Optimization",
      description: "Engage audiences with compelling, search-engine-friendly content. We refine your website’s content for relevance, readability, and keyword integration. This helps you rank higher while delivering value to your readers.",
      servicesImg: ServicesImg4,
      url: "#"
    },
    {
      title: "Keyword Research",
      description: "Identify the best keywords to attract your audience. We analyze trends, competitors, and search behavior to select targeted terms, ensuring your website gains visibility and effectively reaches potential customers.",
      servicesImg: ServicesImg5,
      url: "#"
    },
    {
      title: "Conversion Rate Optimization",
      description: "Transform website visitors into loyal customers. We analyze user behavior, streamline the sales funnel, and implement proven tactics to increase conversions, ensuring your business thrives online.",
      servicesImg: ServicesImg6,
      url: "#"
    },
    {
      title: "Link Building",
      description: "Enhance authority with quality backlinks. Our strategies build trustworthy connections with high-ranking sites, boosting your website’s credibility and improving its position in search engine results.",
      servicesImg: ServicesImg7,
      url: "#"
    },
    {
      title: "Voice Search Optimization",
      description: "Optimize your site for voice-driven searches to stay ahead of the competition. We tailor strategies focusing on conversational keywords, ensuring your business appears when users make queries through smart speakers or mobile assistants.",
      servicesImg: ServicesImg8,
      url: "#"
    },
    {
      title: "SEO for New Websites",
      description: "Lay the foundation for success with SEO tailored for new websites. From keyword integration to technical setup, we ensure your site gains visibility and attracts traffic immediately.",
      servicesImg: ServicesImg9,
      url: "#"
    }
  ];
  const seoRole = [
    {
      title: "Increased Website Traffic",
      description: "SEO boosts your website's visibility, attracting more organic traffic and potential customers for your products or services.",
      img: Role1,
      url: "#"
    },
    {
      title: "Targeted Audience Engagement",
      description: "Targeting specific keywords in SEO helps you reach an audience actively searching for your offerings, boosting conversion opportunities.",
      img: Role2,
     url: "#"
    },
    {
      title: "Improved User Experience",
      description: "SEO improves website speed, mobile-friendliness, and navigation, enhancing user experience and encouraging purchases.",
      img: Role3,
     url: "#"
    },
    {
      title: "Higher Conversion Rates",
      description: "With optimized content and a streamlined sales funnel, SEO drives qualified leads, efficiently turning visitors into paying customers.",
      img: Role4,
     url: "#"
    },
    {
      title: "Cost-Effective Marketing",
      description: "SEO is a long-term, cost-efficient marketing solution that generates consistent returns by reducing reliance on paid ads over time.",
      img: Role5,
    url: "#"
    },
    {
      title: "Building Brand Credibility",
      description: "Higher search rankings build trust and authority, leading customers to choose your business over competitors and driving revenue growth.",
      img: Role6,
     url: "#"
    }
  ];



  const percentage1 = 91;
  const percentage2 = 61;
  const percentage3 = 14.6;

  function onChange(value) {
    if (value) {
      setCaptcha(false);
    }
  }
  const selectService = (val) => {
    if (services.includes(val)) {
      var arr = services.filter(value => value != val);
      setServices(arr);
    } else {
      var arr = services.push(val);
    }
  }
  const SubmitServicedetails = (e) => {
    e.preventDefault();
    captchaRef.current.reset();
    if (phone.length > 7) {
      const data = {
        first_name: firstname,
        last_name: lastname,
        email: email,
        phone: phone.length ? `+${phone}` : "",
        website: website,
        company_name: business,
        services: services
      };
      setLoad(true);
      axios
        .post(`${baseURL}/service/send_service_request`, data, {
          headers: postHeader,
        })
        .then((res) => {
          if (res.data.status == 1) {
            swal(res.data.message, { icon: "success" });
            setFirstname("");
            setLastname("");
            setPhone("");
            setEmail("");
            setMessage("");
            setTime("10:00");
            setTimefeild("");
            setTimezone("");
            setBusiness("");
            setWebsite("");
            setLoad(false);
            history.push('./thankyou')
          } else {
            swal(res.data.message, { icon: "warning" });
            setLoad(false);
          }
        })
        .catch((error) => {
          if (error) {
            swal("something went wrong", { icon: "error" });
            setLoad(false);
          }
        });
    }
    else {
      setPhoneError('Please Enter Valid Number');
    }
  };


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          #1 SEO Services | Expert SEO Agency for Your Jewelry Business
        </title>
        <meta
          name="description"
          content="Belgium Webnet is an SEO agency offering top-notch services across all industries. Get our experts on board today. Book an appointment. "
        ></meta>
        <meta name="keywords" content="Best search engine optimization company"></meta>
      </Helmet>
      <section className="bw_wdd_section">
        <div className="bw_all_banner bw_wdd_banner">
          <Container className="bw_custome_container">
            <Row>
              <Col>
                <h1 className="text-center text-white text-capitalize">
                  Search Engine Optimization - #1 Expert SEO Agency that Maximizes ROI
                </h1>
                <ul className=" d-flex bw_Contact_link text-center pl-0">
                  <li>
                    <NavLink to="/" className="text-white">
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <BsChevronRight className="text-white" />
                  </li>
                  <li>
                    <NavLink to="/digital-marketing" className="text-white">
                      Digital Marketing
                    </NavLink>
                  </li>
                  <li>
                    <BsChevronRight className="text-white" />
                  </li>
                  <li>
                    <NavLink to="/digital-marketing/seo" className="text-white">
                      SEO
                    </NavLink>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bw_website_desing">
          <Container className="bw_custome_container">
            <Row>
              <Col
                sm={12}
                md={6}
                lg={6}
                className="bw_website_deigns_innerbox1"
              >
                <div>
                  <div>
                    <h2>
                      SEO Marketing Company
                    </h2>
                    <p>
                      Get result-driven SEO services with Belgium Webnet, a reputed digital marketing company in the USA. We have helped many jewelry companies rank on the first page and are now willing to help companies from other industries.
                    </p>
                    <p>Our team focuses on data-driven results, not guesswork.  </p>
                    <p>Your Free SEO Audit is a Click Away </p>
                    <button  className="button_blueee" style={{cursor:'pointer'}} onClick={scrollToSection}>Click Here</button>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6} className="bw_wdd_img_right_box">
                <div>
                  <LazyLoadImage
                    src={WddImageSeo}
                    alt="Web Development &amp; design image"
                    className="img-fluid"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bw_seo_Agency_Section">
<Container className="bw_custome_container">
  <Row>
    <Col sm={12} md={12} lg={12}>
    <div className="bw_seo_Agency_Section_box">
<h2 className="text-white">A Trusted SEO Agency That Provides Dominating Digital Growth with Proven, Long-Lasting Results</h2>
<p>We understand your urgency in reaching your target audience as soon as possible. We can certainly do that for your business. 
</p>
    </div>
    </Col>
  </Row>
  </Container>
        </div>
        {/* Servicess */}
        <section className="bw_new_branding_section bw_new_branding_desktop hm_branding_box_main seo_services_section">
          <Container className="bw_custome_container">
            <Row>
              <Col lg={12} xs={12}>
                <div className="bw_new_branding_main_title">
                  <h2>Our Search Engine Optimization Services </h2>
                </div>
              </Col>
            </Row>

            <Row>
              {seoServices.map((item, i) => (
                <Col lg={4} sm={6} xs={6}>
                  <div className="bw_new_branding_box ">
                    <div className="bw_new_branding_box_icon">
                      {/* <Link to="#" onClick={(e) => e.preventDefault()}> */}
                        <LazyLoadImage src={item.servicesImg} alt="Brand Image" />
                      {/* </Link> */}
                    </div>
                    <h2>
                      {/* <Link to="#" onClick={(e) => e.preventDefault()} className="colorBlack"> */}
                        {item.title}
                      {/* </Link> */}
                    </h2>
                    <p>
                      {item.description}
                    </p>
                  </div>
                </Col>

              ))}

            </Row>
          </Container>
        </section>
        <div className="bw_why_choose_us">
          <Container className="bw_custome_container">
            <Row>
              <Col sm={12} md={12} lg={12}>
                <div>
                  <h2>
                    What Sets Us Apart?
                  </h2>
                  <ul
                    className="pl-0"
                  >
                    <li>
                      <p>Tailored SEO solutions for every client.</p>
                    </li>
                    <li>
                      <p>
                        Successful SEO method for lead generation using SMO,
                        SMM, content marketing, referrals, etc.
                      </p>
                    </li>
                    <li>
                      <p>
                        Increased traffic results in higher lead close rates.
                      </p>{" "}
                    </li>
                    <li>
                      {" "}
                      <p>Reduced advertising costs with SEO promotions.</p>
                    </li>
                    <li>
                      <p>
                        Enhanced brand awareness and credibility with strategic
                        SEO.
                      </p>{" "}
                    </li>
                  </ul>
                </div>
              </Col>

            </Row>
          </Container>
        </div>
        <div className="bw_faq_section search_engin_optimizationnn pb-0">
          <Container className="bw_custome_container">
            <Row className="w-100 m-auto">
              <Col>
                <h2>
                  What does BW’s SEO Strategy include?
                </h2>
                <p className="text-center">Here’s the SEO strategy we apply for your business:</p>
              </Col>
            </Row>
            <Row className="mb-lg-4">
              <Col sm={12} md={12} lg={12}>
                <div className="slider_seo">
                  <Slider {...settingsSeo} className="slider_seo_main">

                    {seoStrategy.map((item) => (
                      <div className="bw_wdd_dervices_box_outer">
                        <div className="bw_wdd_dervices_box mb-0"
                        >
                          <h3>{item.title} </h3>
                          <p>{item.description}
                          </p>
                        </div>
                      </div>

                    ))
                    }

                  </Slider>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="result_with_seo_section bw_faq_section">
          <Container className="bw_custome_container">
            <Row className="w-100 m-auto">
              <Col sm={12} md={12} lg={12}>
                <h2>
                  Our Results With SEO In The Jewelry Niche
                </h2>
                {/* <p className="text-center">Here’s the SEO strategy we apply for your business:</p> */}
              </Col>
              <Col sm={12} md={12} lg={12} className="m-auto">
                <div className="bw_seo_progress_circle_outer">
                  <ul>
                    <li>
                      <div className="bw_seo_progress_circle">
                        <CircularProgressbar value={percentage1} text={`${percentage1}%`} />
                        <p>91% of businesses reported that SEO positively impacted their website performance and marketing goals in 2024.</p>

                      </div>
                    </li>
                    <li>
                      <div className="bw_seo_progress_circle">
                        <CircularProgressbar value={percentage2} text={`${percentage2}%`} />
                        <p>61% of B2B businesses stated that SEO and organic traffic generate more leads than any other marketing initiative.</p>
                      </div>
                    </li>
                    <li>
                      <div className="bw_seo_progress_circle">
                        <CircularProgressbar value={percentage3} text={`${percentage3}%`} />
                        <p>The close rate for leads from search engines is 14.6%, indicating that SEO-driven traffic tends to convert better.</p>
                      </div>
                    </li>
                  </ul>

                </div>
              </Col>



            </Row>
          </Container>
        </div>
        {/* Why SEO Is Vital  */}
        <div className="bw_faq_section search_engin_optimizationnn Vital_section pb-0">
          <Container className="bw_custome_container">
            <Row className="w-100 m-auto">
              <Col>
                <h2>
                  Why SEO Is Vital for Your Business
                </h2>
                <p className="text-center">Here are some reasons why SEO is essential for your business:     </p>
              </Col>
            </Row>
            <Row className="mb-lg-4">
              <Col sm={12} md={12} lg={12}>
                <div className="slider_seo">
                  <Slider {...settingsSeo} className="slider_seo_main">

                    {seoBenefits.map((item) => (
                      <div className="bw_wdd_dervices_box_outer">
                        <div className="bw_wdd_dervices_box benefits mb-0"
                        >
                          <h3>{item.heading} </h3>
                          <p>{item.content}
                          </p>
                        </div>
                      </div>

                    ))
                    }

                  </Slider>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* the role of seo  */}
        <section className="bw_new_branding_section bw_new_branding_desktop hm_branding_box_main seo_services_section">
          <Container className="bw_custome_container">
            <Row>
              <Col lg={12} xs={12}>
                <div className="bw_new_branding_main_title">
                  <h2 style={{ color: "#000" }}>The Role Of SEO in Driving Revenue Growth </h2>
                </div>
              </Col>
            </Row>

            <Row>
              {seoRole.map((item, i) => (
                <Col lg={4} sm={6} xs={6}>
                  <div className="bw_new_branding_box ">
                    <div className="bw_new_branding_box_icon">
                      {/* <Link to="#" onClick={(e) => e.preventDefault()}> */}
                        <LazyLoadImage src={item.img} alt={item.title + " Image"} />
                      {/* </Link> */}
                    </div>
                    <h3>
                      {/* <Link to="#" onClick={(e) => e.preventDefault()} className="colorBlack"> */}
                        {item.title}
                      {/* </Link> */}
                    </h3>
                    <p>
                      {item.description}
                    </p>
                  </div>
                </Col>

              ))}

            </Row>
          </Container>
        </section>
        {/* Book An Appointment section */}
        <div className="bw_services_form_section book_an_App_seo"    ref={sectionRef}>
          <Container>
            <Row className="w-100 m-auto">
              <Col>
                <div
                  className="bw_contact_form_outer_box"
               
                >
                  <h2>Let's Book An Appointment</h2>
                  <div className="bw_contact_form_inner_box">
                    <Form
                      className="bw_contact_us_form"
                      onSubmit={SubmitServicedetails}
                    >
                      <Row>
                        <Col sm={12} md={12} lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Company Name*</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Company Name"
                              value={business}
                              onChange={(e) => setBusiness(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="First Name"
                              value={firstname}
                              onChange={(e) => setFirstname(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Last Name"
                              value={lastname}
                              onChange={(e) => setLastname(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Email Id"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Phone Number</Form.Label>
                            <PhoneInput
                              disableSearchIcon={true}
                              enableSearch={true}
                              country={"us"}
                              value={phone}
                              onChange={setPhone}
                              onlyCountries={[
                                "us",
                                "cn",
                                "in",
                                "au",
                                "jp",
                                "hk",
                                "kw",
                                "gb",
                                "es",
                              ]}
                              inputProps={{
                                name: "phone",
                                required: true,
                                autoFocus: false,
                              }}
                              isValid={(value, country) => {
                                console.log('value', value.length);
                                if (value.length < 10) {
                                  return "please enter valid number";
                                } else {
                                  return true;
                                }
                              }}

                            />
                            {!phoneError.length ? '' : <p className="phone_field_error font-weight-light text-danger">{phoneError}</p>}
                          </Form.Group>
                        </Col>

                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Website</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Website"
                              value={website}
                              onChange={(e) => setWebsite(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={12} lg={12}>

                          <Form.Label>Services</Form.Label>
                          <FormGroup aria-label="position" row className="mb-3">
                            <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
                              <FormControlLabel
                                value="end"
                                label="Website Design & Development"
                                control={<Checkbox />}
                                labelPlacement="end"
                                className="bwn_consultation_form_checkbox"
                                onChange={() => selectService("Website Design & Development")}
                              />
                            </Col>


                            <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
                              <FormControlLabel
                                value="end"
                                control={<Checkbox />}
                                label="Shopify Development"
                                labelPlacement="end"
                                className="bwn_consultation_form_checkbox"
                                onChange={() => selectService("Shopify Development")}
                              />
                            </Col>

                            <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
                              <FormControlLabel
                                value="end"
                                control={<Checkbox />}
                                label="Social Media Marketing"
                                labelPlacement="end"
                                className="bwn_consultation_form_checkbox"
                                onChange={() => selectService("Social Media Marketing")}
                              />
                            </Col>

                            <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
                              <FormControlLabel
                                value="end"
                                control={<Checkbox />}
                                label="Search Engine Optimization"
                                labelPlacement="end"
                                className="bwn_consultation_form_checkbox"
                                onChange={() => selectService("Search Engine Optimization")}
                              />
                            </Col>

                            <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
                              <FormControlLabel
                                value="end"
                                control={<Checkbox />}
                                label="Email Marketing"
                                labelPlacement="end"
                                className="bwn_consultation_form_checkbox"
                                onChange={() => selectService("Email Marketing")}
                              />
                            </Col>

                            <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
                              <FormControlLabel
                                value="end"
                                control={<Checkbox />}
                                label="Paid Marketing"
                                labelPlacement="end"
                                className="bwn_consultation_form_checkbox"
                                onChange={() => selectService("Paid Marketing")}
                              />
                            </Col>
                          </FormGroup>

                        </Col>
                        <Col sm={12} md={12} lg={12}>
                          <div className="rcs_cap_main">
                            <ReCAPTCHA
                              className="rcs_g-recaptcha"
                              ref={captchaRef}
                              sitekey="6Lc6LUscAAAAAKO2DYPGrIPSnwx3fzROCxJzNWQ0"
                              onChange={onChange}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <button disabled={load || captcha ? true : false}>
                          {load ? (
                            <div className="bw_loader_style">
                              <Loader
                                type="spinner-default"
                                bgColor={"#FFFFFF"}
                                color={"#FFFFFF"}
                                size={20}
                              />
                            </div>
                          ) : (
                            "submit"
                          )}
                        </button>
                      </Row>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* faq section */}
        <div className="bw_faq_section">
          <Container className="bw_custome_container">
            {/* <Row className="w-100 m-auto">
              <Col>
                <h2>
                  Why Choose Our SEO Services
                </h2>
              </Col>
            </Row>
            <Row className="mb-lg-4">
              <Col sm={12} md={6} lg={6} xl={3}>
                <div
                  className="bw_wdd_dervices_box"
                >
                  <LazyLoadImage src={SeoImg1} alt="Service Icon" />
                  <p>SEO that helps build a definite brand image </p>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6} xl={3}>
                <div
                  className="bw_wdd_dervices_box"
                >
                  <LazyLoadImage src={SeoImg2} alt="Service Icon" />
                  <p>Save advertising costs with SEO Promotions</p>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6} xl={3}>
                <div
                  className="bw_wdd_dervices_box"
                >
                  <LazyLoadImage src={SeoImg3} alt="Service Icon" />
                  <p>Analyzing the usability, speed, and design of your site</p>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6} xl={3}>
                <div
                  className="bw_wdd_dervices_box"
                >
                  <LazyLoadImage src={SeoImg4} alt="Service Icon" />
                  <p>Generate maximum traffic and build your customer base</p>
                </div>
              </Col>
            </Row> */}
            <Row className="w-100 m-auto">
              <Col>
                <h2>
                  FAQ’S
                  {/* about Search Engine Optimization */}
                </h2>
              </Col>
            </Row>
            <Row className="w-100 m-auto">
              <Col className="px-md-0">
                <div
                  className="bw_wdd_faq_accordian"
                >
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel1" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h3 className="bw_accordian_heading">
                        What is SEO?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        SEO (Search Engine Optimization) improves a website’s visibility on search engines. Optimizing content, technical aspects, and user experience increases organic traffic and drives higher rankings.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel2" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <h3 className="bw_accordian_heading">
                        What are the benefits of SEO?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        SEO increases website visibility, drives targeted traffic, enhances user experience, and boosts brand credibility. By optimizing your site, you can generate more leads, increase sales, and ensure long-term business growth.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel3" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <h3 className="bw_accordian_heading">
                        How do I choose the best SEO company?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        Choose an SEO company with a proven track record, transparent processes, and tailored strategies. Look for expertise in your industry, positive client reviews, and measurable results to ensure they align with your business goals.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel4"}
                    onChange={handleChange("panel4")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel4" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel4a-content"
                      id="panel4a-header"
                    >
                      <h3 className="bw_accordian_heading">
                        What type of businesses do you work with?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        We work with businesses of all sizes across various industries, including retail, e-commerce, hospitality, real estate, and more. Our SEO strategies are customized to meet each business's needs and goals.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel5"}
                    onChange={handleChange("panel5")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel5" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel5a-content"
                      id="panel5a-header"
                    >
                      <h3 className="bw_accordian_heading">
                        Do you do SEO for websites you did not create?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        Yes, we provide SEO services for websites we didn’t create. Our team will assess your site, optimize content, and implement strategies to improve rankings and performance, regardless of the website’s design origin.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel6"}
                    onChange={handleChange("panel6")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel6" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel6a-content"
                      id="panel6a-header"
                    >
                      <h3 className="bw_accordian_heading">
                        How long does it take for SEO to start working?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        SEO results can take 3 to 6 months, depending on competition, website history, and SEO efforts. Although initial improvements may be visible sooner, significant results typically require continuous optimization and monitoring over time.

                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel7"}
                    onChange={handleChange("panel7")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel7" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel7a-content"
                      id="panel7a-header"
                    >
                      <h3 className="bw_accordian_heading">
                        How will you determine the best keywords for your business?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        We perform thorough keyword research, analyzing search volume, competition, and user intent. By understanding your business goals and target audience, we identify keywords that will drive relevant traffic and generate quality leads for your business.

                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel8"}
                    onChange={handleChange("panel8")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel8" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel8a-content"
                      id="panel5a-header"
                    >
                      <h3 className="bw_accordian_heading">
                        How do you modify your SEO strategy for different industries?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        We tailor SEO strategies to each industry’s unique challenges and target audience. By focusing on specific keywords, content, and trends relevant to the industry, we ensure effective optimization and measurable results for your business.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </Container>
        </div>


      </section>
    </>
  );
};

export default React.memo(SearchEngineOptimization);
