import React, { useRef, useState } from "react";
import "../../Assets/css/services.css";
import { Container, Row, Col, Form, Card } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { baseURL, postHeader } from "../../Helpers/request";
import swal from "sweetalert";
import "../../Assets/css/Sweetalert.css";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "react-js-loader";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useHistory } from "react-router-dom";


import BrandLogoSlider from "../Front/BrandLogoSlider.js";
const FreeConsaltingPage = () => {
    const history = useHistory();
    const captchaRef = useRef(null);
    const [firstname, setFirstname] = React.useState("");
    const [lastname, setLastname] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [phoneError, setPhoneError] = React.useState("");
    const [business, setBusiness] = React.useState("");
    const [website, setWebsite] = React.useState("");
    const [time, setTime] = React.useState("10:00");
    const [timefeild, setTimefeild] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [timezone, setTimezone] = React.useState("");
    const [load, setLoad] = React.useState(false);
    const [captcha, setCaptcha] = React.useState(true);
    const [services, setServices] = useState([]);

    const bottomRef = useRef(null);

    function onChange(value) {
        if (value) {
            setCaptcha(false);
        }
    }
    const selectService = (val) => {
        if (services.includes(val)) {
            var arr = services.filter(value => value != val);
            setServices(arr);
        } else {
            var arr = services.push(val);
        }
    }
    const SubmitServicedetails = (e) => {
        e.preventDefault();
        captchaRef.current.reset();
        if (phone.length > 7) {
            const data = {
                first_name: firstname,
                last_name: lastname,
                email: email,
                phone: phone.length ? `+${phone}` : "",
                website: website,
                company_name: business,
                services: services
            };
            setLoad(true);
            axios
                .post(`${baseURL}/service/send_service_request`, data, {
                    headers: postHeader,
                })
                .then((res) => {
                    if (res.data.status == 1) {
                        swal(res.data.message, { icon: "success" });
                        setFirstname("");
                        setLastname("");
                        setPhone("");
                        setEmail("");
                        setMessage("");
                        setTime("10:00");
                        setTimefeild("");
                        setTimezone("");
                        setBusiness("");

                        setLoad(false);
                        history.push("/thankyou");
                    } else {
                        swal(res.data.message, { icon: "warning" });
                        setLoad(false);
                    }
                    setWebsite("");
                })
                .catch((error) => {
                    if (error) {
                        swal("something went wrong", { icon: "error" });
                        setLoad(false);
                    }
                });
        }
        else {
            setPhoneError('Please Enter Valid Number');
        }
    };

    return (
        <>
            <section className="free_consalting_page bw_services_section_main">
                <Container className="bw_custome_container container">
                    <Row>
                        <Col lg={6} className="mb-4 mb-lg-0">
                            <div
                                className="bw_contact_form_outer_box" ref={bottomRef}
                            >
                                <h2>
                                    Lets Grow Your Business!
                                </h2>
                                <div className="bw_contact_form_inner_box">
                                    <Form
                                        className="bw_contact_us_form"
                                        onSubmit={SubmitServicedetails}

                                    >
                                        <Row>
                                            <Col sm={12} md={12} lg={12}>
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="exampleForm.ControlInput1"
                                                >
                                                    <Form.Label>Company Name*</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Company Name"
                                                        value={business}
                                                        onChange={(e) => setBusiness(e.target.value)}
                                                        required
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col sm={12} md={6} lg={6}>
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="exampleForm.ControlInput1"
                                                >
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="First Name"
                                                        value={firstname}
                                                        onChange={(e) => setFirstname(e.target.value)}
                                                        required
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col sm={12} md={6} lg={6}>
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="exampleForm.ControlInput1"
                                                >
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Last Name"
                                                        value={lastname}
                                                        onChange={(e) => setLastname(e.target.value)}
                                                        required
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col sm={12} md={12} lg={12}>
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="exampleForm.ControlInput1"
                                                >
                                                    <Form.Label>Email Address</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Email Id"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col sm={12} md={6} lg={6}>
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="exampleForm.ControlInput1"
                                                >
                                                    <Form.Label>Phone Number</Form.Label>
                                                    <PhoneInput
                                                        disableSearchIcon={true}
                                                        enableSearch={true}
                                                        country={"us"}
                                                        value={phone}
                                                        onChange={setPhone}
                                                        onlyCountries={[
                                                            "us",
                                                            "cn",
                                                            "in",
                                                            "au",
                                                            "jp",
                                                            "hk",
                                                            "kw",
                                                            "gb",
                                                            "es",
                                                        ]}
                                                        inputProps={{
                                                            name: "phone",
                                                            required: true,
                                                            autoFocus: false,
                                                        }}
                                                        isValid={(value, country) => {
                                                            console.log('value', value.length);
                                                            if (value.length < 10) {
                                                                return "please enter valid number";
                                                            } else {
                                                                return true;
                                                            }
                                                        }}

                                                    />
                                                    {!phoneError.length ? '' : <p className="phone_field_error font-weight-light text-danger">{phoneError}</p>}
                                                </Form.Group>
                                            </Col>

                                            <Col sm={12} md={6} lg={6}>
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="exampleForm.ControlInput1"
                                                >
                                                    <Form.Label>Website</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Website"
                                                        value={website}
                                                        onChange={(e) => setWebsite(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col sm={12} md={12} lg={12}>

                                                <Form.Label>Services</Form.Label>
                                                <FormGroup aria-label="position" row className="mb-3">
                                                    <Col sm={12} md={6} lg={6} className="pl-0">
                                                        <FormControlLabel
                                                            value="end"
                                                            label="Website Design & Development"
                                                            control={<Checkbox />}
                                                            labelPlacement="end"
                                                            className="bwn_consultation_form_checkbox"
                                                            onChange={() => selectService("Website Design & Development")}
                                                        />
                                                    </Col>


                                                    <Col sm={12} md={6} lg={6} className="pl-0">
                                                        <FormControlLabel
                                                            value="end"
                                                            control={<Checkbox />}
                                                            label="Shopify Development"
                                                            labelPlacement="end"
                                                            className="bwn_consultation_form_checkbox"
                                                            onChange={() => selectService("Shopify Development")}
                                                        />
                                                    </Col>

                                                    <Col sm={12} md={6} lg={6} className="pl-0">
                                                        <FormControlLabel
                                                            value="end"
                                                            control={<Checkbox />}
                                                            label="Social Media Marketing"
                                                            labelPlacement="end"
                                                            className="bwn_consultation_form_checkbox"
                                                            onChange={() => selectService("Social Media Marketing")}
                                                        />
                                                    </Col>

                                                    <Col sm={12} md={6} lg={6} className="pl-0">
                                                        <FormControlLabel
                                                            value="end"
                                                            control={<Checkbox />}
                                                            label="Search Engine Optimization"
                                                            labelPlacement="end"
                                                            className="bwn_consultation_form_checkbox"
                                                            onChange={() => selectService("Search Engine Optimization")}
                                                        />
                                                    </Col>

                                                    <Col sm={12} md={6} lg={6} className="pl-0">
                                                        <FormControlLabel
                                                            value="end"
                                                            control={<Checkbox />}
                                                            label="Email Marketing"
                                                            labelPlacement="end"
                                                            className="bwn_consultation_form_checkbox"
                                                            onChange={() => selectService("Email Marketing")}
                                                        />
                                                    </Col>

                                                    <Col sm={12} md={6} lg={6} className="pl-0">
                                                        <FormControlLabel
                                                            value="end"
                                                            control={<Checkbox />}
                                                            label="Paid Marketing"
                                                            labelPlacement="end"
                                                            className="bwn_consultation_form_checkbox"
                                                            onChange={() => selectService("Paid Marketing")}
                                                        />
                                                    </Col>


                                                </FormGroup>

                                            </Col>
                                            <Col sm={12} md={12} lg={12}>
                                                <div className="rcs_cap_main">
                                                    <ReCAPTCHA
                                                        className="rcs_g-recaptcha"
                                                        ref={captchaRef}
                                                        sitekey="6Lc6LUscAAAAAKO2DYPGrIPSnwx3fzROCxJzNWQ0"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <button disabled={load || captcha ? true : false}>
                                                {load ? (
                                                    <div className="bw_loader_style">
                                                        <Loader
                                                            type="spinner-default"
                                                            bgColor={"#FFFFFF"}
                                                            color={"#FFFFFF"}
                                                            size={20}
                                                        />
                                                    </div>
                                                ) : (
                                                    "Book a Free Consultation Call"
                                                )}
                                            </button>
                                        </Row>
                                    </Form>
                                </div>
                            </div>
                        </Col>

                        <Col lg={6} className="left_section">
                            <div className="text-center text-md-left sub-heading">
                                <span className="text-blue fw-700">BELGIUM WEBNET  </span>
                            </div>

                            <h2 className="head-4 fw-700 text-dark d-block text-center text-md-left mb-4"> See How We Can Make Your  <span className="text-blue"> Jewelry Business </span> Grow Online </h2>

                            <p className="para-2 text-center text-md-left ">Web Design | SEO | Paid Media | Social Media | Email | Content Marketing | Analytics | Strategy</p>

                            <ul>
                                <li>
                                    <strong>  SEO – </strong> Unlock more organic traffic and dominate search engine rankings. See measurable results for your jewelry business.
                                </li>
                                <li>
                                    <strong> Web Design & Development – </strong> Beautiful, responsive, and user-friendly websites tailored to showcase your jewelry collections.
                                </li>
                                <li>
                                    <strong>  Paid Media –</strong> Targeted advertising strategies with clear ROI to attract more customers.
                                </li>
                                <li>
                                    <strong> Social Media –</strong> Build your jewelry brand’s presence on platforms that matter most.
                                </li>
                                <li>
                                    <strong>Email Marketing –</strong> Drive engagement and sales through impactful email campaigns.
                                </li>
                                <li>
                                    <strong>Content Marketing – </strong> Create captivating content that connects with your audience, builds trust, and boosts traffic.
                                </li>
                                <li>
                                    <strong>Analytics –</strong> Gain insights into customer behavior and optimize for success.
                                </li>
                                <li>
                                    <strong>Strategy – </strong> Develop a comprehensive digital marketing plan to grow your jewelry business.
                                </li>
                                <li>
                                    <strong>AI-Powered Insights – </strong>  Leverage the power of artificial intelligence to optimize your marketing strategy and make data-driven decisions that drive growth.
                                </li>

                            </ul>
                        </Col>
                    </Row>


                    <BrandLogoSlider />
                </Container>

            </section>
        </>
    );
};

export default React.memo(FreeConsaltingPage);
